
/* eslint-disable no-unused-vars */
import { h } from 'hyperapp'
import { scrollElement } from '../utils'

export const DialogOptions = () => (state, actions) => {
    // const isCollapsed = !isActive

    if (state.dialogOptions && state.dialogOptions.isActive && state.dialogOptions.options && state.dialogOptions.options.length > 0) {
        const { options, path, messageGroupId } = state.dialogOptions
        const selectableDialogOptions = options.filter(option => !option.isSelected)

        return (
            <ol
                class="cxco-c-dialog"
                data-id={messageGroupId}
                data-layout={`${state.dialogOptionsLayout === 'horizontal' ? 'horizontal' : 'vertical'}`}
            >
                {
                    selectableDialogOptions.map(({ text, isHidden, id }) => (
                        <li
                            class={`cxco-c-dialog__item  cxco-u-animation  cxco-u-animation--fade-in-left  ${isHidden ? 'cxco-u-hidden' : ''}`}
                            data-id={id}
                            onupdate={(elm, oldAttrs) => {
                                if (elm.className.indexOf('cxco-u-hidden') < 0 && oldAttrs.class.indexOf('cxco-u-hidden') >= 0) {
                                    scrollElement(elm, 'question')
                                }
                            }}>
                            <button
                                class="cxco-c-dialog__option"
                                onclick={e => {
                                    const askPayload = { question: text, dialogPath: path }

                                    // If one opt is prev selected, you edited your choice, needs to show the "new topic" divider
                                    if (options.find(option => option.isSelected)) {
                                        askPayload.isNewTopic = true
                                    }
                                    actions.selectDialogOption({
                                        askPayload,
                                        messageGroupId,
                                        id
                                    })
                                }}>
                                {text}
                            </button>
                        </li>))}
            </ol>)
    }
}
