/* eslint-disable no-unused-vars */
import { h } from 'hyperapp'
import { SendIcon, StepOver } from './icons'
export const Input = () => (state, actions) => {
    const id = `cxcoChatInput${state.wccId}`
    const useTextArea = state.inputType === 'text'

    return (
        <cxco-c-field class={`cxco-c-field  ${state.showInput ? '' : ' cxco-u-hidden'}`} data-state={`${state.inputState}`} >

            { useTextArea ? (
                <textarea
                    class="cxco-c-field__input"
                    id={id}
                    placeholder={state.inputPlaceholder ? state.inputPlaceholder : ''}
                    value={state.text}
                    rows={state.rows}
                    autocomplete="off"
                    oncreate={e => actions.onCreate(e)}
                    onkeyup={e => actions.onKeyUp(e)}
                    onkeydown={e => actions.onKeyDown(e)}
                    oninput={e => actions.onInput(e)}
                    onfocus={e => actions.onFocus(e)}
                    onblur={e => actions.onBlur(e)}
                />) : (
                <input
                    class="cxco-c-field__input"
                    id={id}
                    placeholder={state.inputPlaceholder ? state.inputPlaceholder : ''}
                    value={state.text}
                    onkeyup={e => actions.onKeyUp(e)}
                    oninput={e => actions.onInput(e)}
                    onfocus={e => actions.onFocus(e)}
                    onblur={e => actions.onBlur(e)}
                    type={state.inputType}></input>)
            }

            <button class={`cxco-c-field__button  ${state.inTDialog ? '' : 'cxco-u-hidden'}`}
                onclick={e => actions.stepOverTDialog()}>
                <StepOver />
            </button>
            <button
                class="cxco-c-field__button  cxco-c-field__button--contained"
                onclick={e => actions.ask({ question: state.text.trim(), dialogPath: state.dialogOptions?.path })}>
                <SendIcon/>
            </button>
        </cxco-c-field>
    )
}
