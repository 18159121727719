/* eslint-disable no-unused-vars */
import { h } from 'hyperapp'
import { Feedback } from './views/feedback'
import { Notify } from './views/notify'
import { MetaData } from './views/metadata'
import { Divider } from './views/divider'
import { Paragraph } from './views/messageTypes/paragraph'
import { TextNode } from './views/messageTypes/textnode'
import { Anchor } from './views/messageTypes/anchor'
import { Bubble } from './views/messageTypes/bubble'
import { Image } from './views/messageTypes/image'
import { MediaBlock } from './views/messageTypes/mediaBlock'
import { Video } from './views/messageTypes/video'

export const renderer = ({ id: messageGroupId, isNewTopic, newTopic, feedbackOptions, dialogOptions, type, isCTAMessage, isInline, setDividerBefore, isParagraph, feedbackSubmittedMessage }) => msgActions => (
    acc,
    message,
    index,
    originalMessageGroup
) => {
    const { mediaType, timestamp } = message
    const lastMessage = index === originalMessageGroup.length - 1
    let element

    if (isNewTopic && setDividerBefore) {
        acc.push(<Divider newTopic={newTopic} isNewTopic={isNewTopic} />)
    }

    const messagePayload = { type, isCTAMessage, isInline, ...message, msgActions }

    if (isParagraph) {
        acc.push(<Paragraph text={messagePayload.inlineTokens?.[0]?.text} />)

        return acc
    }

    switch (mediaType) {
    case 'paragraph':
        element = isInline ? TextNode(messagePayload) : Bubble(messagePayload)
        break
    case 'image':
        element = Image(messagePayload)
        break
    case 'video':
        element = Video(messagePayload)
        break
    case 'card':
        element = MediaBlock(messagePayload)
        break
    case 'tile':
        element = MediaBlock(messagePayload)
        break
    case 'link':
        element = Anchor(messagePayload)
        break
    default:
        element = isInline ? TextNode(messagePayload) : Bubble(messagePayload)
        break
    }

    acc.push(element)

    if (
        !isInline
        && lastMessage
        && !message.isHidden
    ) {
        acc.push(
            <MetaData
                id={messageGroupId}
                type={type}
                timestamp={timestamp}
                feedbackOptions={feedbackOptions}
                dialogOptions={dialogOptions}
                isCTAMessage={isCTAMessage}
            />
        )
    }

    if (feedbackOptions && lastMessage && Object.keys(feedbackOptions).length !== 0 && !feedbackOptions.showFeedbackMessage) {
        acc.push(<Feedback id={messageGroupId} feedbackOptions={feedbackOptions}/>)
    }

    if (feedbackOptions && lastMessage && Object.keys(feedbackOptions).length !== 0 && feedbackOptions.showFeedbackMessage) {
        acc.push(
            <Notify
                id={messageGroupId}
                message={feedbackSubmittedMessage}
                show={feedbackOptions.showFeedbackMessage}
                click={msgActions.toggleFeedbackMessage}
                type={'success'}
            />
        )
    }

    if (lastMessage && isNewTopic && !setDividerBefore) {
        acc.push(<Divider newTopic={newTopic} isNewTopic={isNewTopic} />)
    }

    return acc
}
