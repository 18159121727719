/* eslint-disable no-unused-vars */
import { h } from 'hyperapp'

export const Button = ({ text, onclickCallback, classNames }) => (state, actions) => {
    return (<button
        class={`cxco-c-button ${classNames}`}
        onclick={onclickCallback}
    >
        {text}
    </button>)
}
