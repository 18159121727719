/* eslint-disable no-unused-vars */
/* eslint-disable no-return-assign */

import { h } from 'hyperapp'
import { Titlebar } from './titlebar'
import { Conversation } from './conversation'
import { Loader } from './loader'
import { DialogOptions } from './dialogoptions'
import { Input } from './input'
import { Cta } from './cta'
import { Avatar } from './avatar'
import { Action } from './action'
import { Faqs } from './faqs'
import { Menu } from './menu'
import { Modal } from './modal'

export const view = (state, actions) => (
    <div class={`${state.chatLayout === 'inline' ? 'cxco-o-inline' : ''} ${state.isHidden ? ' cxco-u-hidden' : ''}`}>
        <cxco-o-chat class={'cxco-o-chat'}
            data-state={`${state.isOpen || state.chatLayout === 'inline' ? 'open' : 'closed'}`}
            data-layout={`${state.chatLayout}`}
            key={'cxco-chat-window'}
            oncreate={e => {
                actions.botReady()
                actions.initVA()
                actions.setElementQueries(e.getRootNode())
            }}
            onupdate={actions.storeStateInStorage(state)} >
            <Modal shouldShow={state.chatLayout !== 'inline'}/>
            <Menu/>
            <Avatar />
            <Titlebar
                title={state.agentName || state.title}
                subtitle={state.subtitle} />
            <Conversation>
                <DialogOptions />
                <Loader />
            </Conversation>
            <Input />
        </cxco-o-chat >
        <Modal shouldShow={state.chatLayout === 'inline'} />
        <Cta />
        <Action />
        <Faqs />
    </div >)
