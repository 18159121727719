/* eslint-disable no-unused-vars */
import { h } from 'hyperapp'
import { Button } from './button'

export const Modal = ({ shouldShow }) => (state, actions) => {
    if (state.modal.show && shouldShow) {
        return (
            <section class="cxco-c-modal">
                <dialog open class={`cxco-c-modal__dialog  cxco-u-animation  ${state.modal.show ? 'cxco-u-animation--fade-in-bottom' : ''}`}>
                    <h4 class="cxco-c-modal__title">{state.modal.title}</h4>
                    <p class="cxco-c-modal__text">{state.modal.text}</p>
                    <ModalMenu />
                </dialog>
            </section>
        )
    }
}

export const ModalMenu = () => (state, actions) => {
    if (state.modal.buttons) {
        return (<menu class="cxco-c-modal__menu">
            {state.modal.buttons.map(({ text, onClickAction, onClickParam, classNames }) => (<li class="cxco-c-modal__menuitem">
                <Button text={text} onclickCallback={() => actions[onClickAction](onClickParam)} classNames={classNames} />
            </li>))}
        </menu>)
    }
}
