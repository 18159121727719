/* eslint-disable no-unused-vars */
import { h } from 'hyperapp'
import { EditIcon } from './icons'

export const ResetDialog = ({ id, path }) => (state, actions) => {
    return (<a href="#" class="cxco-c-reverter"
        onclick={e => {
            e.preventDefault()
            actions.resetDialogOptions({ id, path })
        }}
    >
        <EditIcon title={state.dialogChooseAgain} id={id}/>
        {/* {state.dialogChooseAgain} */}
    </a>)
}
