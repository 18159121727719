/* eslint-disable no-unused-vars */
import { h } from 'hyperapp'
import { ThumbsUpIcon, ThumbsDownIcon } from './icons'
import { ResetDialog } from './resetdialog'

export const MetaData = ({ type, id, timestamp, feedbackOptions, dialogOptions, isCTAMessage }) => {
    const date = new Date(timestamp)

    return (
        <cxco-c-metadata class={`cxco-c-metadata  ${type === 'question' || isCTAMessage ? 'cxco-c-metadata--inverted' : ''} cxco-u-animation  cxco-u-animation--fade-in-top`}>
            <cxco-c-metadata__item class="cxco-c-metadata__item">
                <time datetime={date}>{date.toLocaleTimeString(window.navigator.language, { timeStyle: 'short' })}</time>
            </cxco-c-metadata__item>
            {
                feedbackOptions
                    ? <cxco-c-metadata__item class="cxco-c-metadata__item  cxco-c-metadata__item--fill">
                        <FeedbackButtons id={id} feedbackOptions={feedbackOptions}></FeedbackButtons>
                    </cxco-c-metadata__item>
                    : ''
            }
            {
                dialogOptions?.isChangeable && type === 'question'
                    ? <cxco-c-metadata__item class="cxco-c-metadata__item  cxco-c-metadata__item--fit">
                        <ResetDialog id={id} path={dialogOptions.path} />
                    </cxco-c-metadata__item> : ''
            }
        </cxco-c-metadata>)
}

export const FeedbackButtons = ({ id, feedbackOptions }) => (state, actions) => {
    const score = { feedbackOptions }

    if (state.feedback.showThumbs && feedbackOptions && !Object.prototype.hasOwnProperty.call(feedbackOptions, 'score')) {
        return (
            <cxco-c-feedback__rating class="cxco-c-feedback__rating" aria-label={score < 0 ? 'negative feedback' : 'positive feedback'}>
                <cxco-c-feedback__point class="cxco-c-feedback__point">
                    <input
                        type="radio"
                        id={`positive-${id}`}
                        name={`positive-${id}`}
                        class="cxco-c-feedback__input"
                        checked={score >= 1}
                        onclick={e => {
                            actions.setMessageFeedbackValues({ id, score: 1, label: 'positive', comment: '' })
                            actions.submitInlineFeedback({ e, id })
                            actions.toggleFeedbackMessage(id)
                        }}
                    />
                    <label
                        class="cxco-c-feedback__label"
                        for={`positive-${id}`}
                    >
                        <ThumbsUpIcon id={id}></ThumbsUpIcon>
                    </label>
                </cxco-c-feedback__point>
                <cxco-c-feedback__point class="cxco-c-feedback__point">
                    <input
                        type="radio"
                        id={`negative-${id}`}
                        name={`negative-${id}`}
                        checked={score < 0}
                        onclick={e => actions.setMessageFeedbackValues({ id, score: -1, label: 'negative' })}
                    />
                    <label
                        for={`negative-${id}`}
                    >
                        <ThumbsDownIcon id={id}></ThumbsDownIcon>
                    </label>
                </cxco-c-feedback__point>
            </cxco-c-feedback__rating>)
    }
}
