
/* eslint-disable no-unused-vars */
import { h } from 'hyperapp'
import { Titlebar } from './titlebar'

export const Faqs = () => (state, actions) => {
    if (state.showFaqs && state.chatLayout === 'inline') {
        return (
            <cxco-o-faq class="cxco-o-faq">
                <Titlebar title={state.faqsTitle} />
                <FaqButtons />
            </cxco-o-faq>
        )
    }
}

/**
 * FAQ Component containing contextual or Page Based FAQs. state.faqsTitle
 */
export const FaqButtons = () => (state, actions) => {
    if (state.faqs && state.faqs.length > 0) {
        return (
            <cxco-c-faq
                class="cxco-c-faq"
                role="listbox"
            >
                {
                    state.faqs.map(({ question, isHidden, id }) => (
                        <cxco-c-faq__item
                            class={`cxco-c-faq__item  cxco-u-animation  cxco-u-animation--fade-in-left ${isHidden ? 'cxco-u-hidden' : ''}`}
                            role="option"
                            data-id={id}
                        >
                            <cxco-c-faq__option
                                class="cxco-c-faq__option"
                                role='button'
                                onclick={e => {
                                    actions.getFaqAnswer({ id, question, isNewTopic: true, setDividerBefore: true })
                                }}>
                                <cxco-c-faq__icon
                                    class="cxco-c-faq__icon"
                                ></cxco-c-faq__icon>
                                <span>{question}</span>
                            </cxco-c-faq__option>
                        </cxco-c-faq__item>))}
            </cxco-c-faq>)
    }
}
