const debug = require('debug')('wcc:storage')

/**
 * WccStorage - Storage for internal, session and local storage
 * @param {String} wccId - The configuration id
 * @param {Boolean} isPreviewMode - Specifies the preview mode
 */

export default class WccStorage {
    constructor (wccId, isPreviewMode) {
        this.isPreviewMode = isPreviewMode
        this.stores = {
            STATE: {
                name: `wcc.${wccId}.state`,
                value: {}
            },
            /** Alias getter for STATE.value */
            get [`wcc.${wccId}.state`] () {
                return this.STATE.value
            },
            /** Alias setter for STATE.value */
            set [`wcc.${wccId}.state`] (val) {
                this.STATE.value = val
            },
            CONFIG: {
                name: `wcc.${wccId}.config`,
                value: {}
            },
            /** Alias getter for CONFIG.value */
            get [`wcc.${wccId}.config`] () {
                return this.CONFIG.value
            },
            /** Alias setter for CONFIG.value */
            set [`wcc.${wccId}.config`] (val) {
                this.CONFIG.value = val
            }
        }
        this.internalStorage = new WccInternalStorage(this.stores)

        // By default we always use the localStorage for state and config storage
        this.storageType = 'localStorage'
    }

    /**
     * Get value from storage
     * @param {String} storeName
     * @return {Object} - stored state
     */
    getStorage (storeName) {
        try {
            const _store = this.storage.getItem(storeName)

            return _store !== null ? JSON.parse(_store) : {}
        } catch (e) {
            return {}
        }
    }

    /**
     * Write value to storage
     * @param {String} storeName
     * @param {Object} value
     */
    setStorage (storeName, value) {
        if (value) {
            this.storage.setItem(storeName, JSON.stringify(value))
        }
    }

    /**
     * Get storage
     * @param {String} component
     * @returns {Object} store value
     */
    get (_component = 'STATE') {
        return this.storeSelector(_component, this.getStorage.bind(this))
    }

    /**
     * Stores value in component storage.
     * @param {Object} value
     * @param {String} component
     */
    store (value, _component = 'STATE') {
        this.storeSelector(_component, this.setStorage.bind(this), value)
    }

    /**
     * Selects the store and executes the mutation
     * @param {String} _component The store component
     * @param {Function} storeMutationCb the store mutation
     * @param {*} args callback arguments
     * @returns storeMutationCb return value
     */
    storeSelector (_component, storeMutationCb, args) {
        try {
            const component = _component.toUpperCase()

            if (Object.keys(this.stores).includes(component)) {
                return storeMutationCb(this.stores[component].name, args)
            } else {
                debug(`component ${component} not found`)
            }

            return {}
        } catch (error) {
            debug(error)
        }
    }

    /**
     * Retrieves the storage type
     * @param {String} type 'localStorage' or 'internalStorage'
     */
    get storageType () {
        return this.storageTypeName
    }

    /**
     * Defines the storage type
     * PreviewMode always uses the internalStorage
     * @param {String} type 'localStorage' or 'internalStorage'
     */
    set storageType (type) {
        const isAppStorage = type === 'internalStorage'

        try {
            if (!(type === 'localStorage' || isAppStorage)) {
                throw new Error(`Storage type "${type}" is invalid`)
            }

            this.storageTypeName = this.isPreviewMode ? 'internalStorage' : type || 'localStorage'
            this.storage = this.isPreviewMode || isAppStorage ? this.internalStorage : window[type] || localStorage
        } catch (error) {
            debug(error)
        }
    }
}

export class WccInternalStorage {
    constructor (stores) {
        this.stores = stores
    }

    /**
       * Alias for local/sessionStorage.getItem
       * @param {String} storeName
       * @returns {String} the store value (stringified Object)
       */
    getItem (storeName) {
        return this.stores[storeName]
    }

    /**
       * Alias for local/sessionStorage.setItem
       * @param {String} storeName
       * @param {Object} value
       */
    setItem (storeName, value) {
        this.stores[storeName] = value
    }
}
