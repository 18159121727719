/* eslint-disable no-unused-vars */
import { h } from 'hyperapp'

export const SendIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <defs>
            <linearGradient id="SendIconGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stop-color="#000000" class="cxco-c-gradient-start  cxco-c-gradient-start--inverted"></stop>
                <stop offset="100%" stop-color="#ffffff" class="cxco-c-gradient-end  cxco-c-gradient-end--inverted"></stop>
            </linearGradient>
        </defs>
        <title>
            Send message
        </title>
        <path fill="url(#SendIconGradient)" fill-rule="evenodd" d="M17.9294167,11 C17.5163365,10.7176237 16.9748736,10.3892493 16.2901689,10.0341046 C13.8948414,8.79168909 10.2051324,7.45262822 5.2515818,6.04069173 C6.18486895,7.90092189 6.75269946,9.54292329 6.93095345,11 L17.9294167,11 L17.9294167,11 Z M17.8258099,13 L6.89053829,13 C6.65768394,14.2843332 6.02443562,15.9005813 5.00159593,17.9598258 C10.0402829,16.5333518 13.7940038,15.1807133 16.2321933,13.9254606 C16.8875596,13.5880585 17.4146806,13.2741638 17.8258099,13 L17.8258099,13 Z M3.469944,6.96973481 C3.24085804,6.51312013 3.18886696,5.9852915 3.32432379,5.49135591 C3.62191307,4.40621359 4.7260194,3.77247809 5.79041446,4.07586742 C15.9301382,6.96604506 21,9.59569599 21,11.9648202 C21,14.3535962 15.8456811,17.0071932 5.53704335,19.9256114 C5.05617654,20.0617468 4.54276781,20.0097908 4.09753583,19.7799358 C3.11153419,19.2709038 2.71698422,18.0433591 3.21628579,17.0381391 C4.39921058,14.6566014 4.99067298,12.9654951 4.99067298,11.9648202 C4.99067298,10.6555104 4.48376332,8.99048189 3.469944,6.96973481 Z" />
    </svg>)

export const CloseIcon = () => {
    const id = Math.random().toString(36).substr(2, 9)

    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <defs>
            <linearGradient id={id} x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stop-color="#000000" class="cxco-c-gradient-start"></stop>
                <stop offset="100%" stop-color="#ffffff" class="cxco-c-gradient-end"></stop>
            </linearGradient>
        </defs>
        <title>
            Close
        </title>
        <path fill={`url(#${id})`} fill-rule="evenodd" d="M13.4261944,11.9997479 L17.7050296,7.72091264 C17.9016765,7.52426573 18.0005042,7.26610362 18.0005042,7.00794151 C18.0005042,6.7497794 17.9016765,6.49262574 17.7050296,6.29497038 C17.3107273,5.90167654 16.6723812,5.90167654 16.2790874,6.29497038 L12.0002521,10.5738056 L7.72141687,6.29497038 C7.32812303,5.90167654 6.68977688,5.90167654 6.2954746,6.29497038 C6.09882768,6.49262574 6,6.7497794 6,7.00794151 C6,7.26610362 6.09882768,7.52426573 6.2954746,7.72091264 L10.5743098,11.9997479 L6.2954746,16.2785831 C6.09882768,16.4752301 6,16.7333922 6,16.9915543 C6,17.2497164 6.09882768,17.50687 6.2954746,17.7045254 C6.49312996,17.9011723 6.75028363,18 7.00844573,18 C7.26660784,18 7.52476995,17.9011723 7.72141687,17.7045254 L12.0002521,13.4256902 L16.2790874,17.7045254 C16.4757343,17.9011723 16.7338964,18 16.9920585,18 C17.2502206,18 17.5073743,17.9011723 17.7050296,17.7045254 C17.9016765,17.50687 18.0005042,17.2497164 18.0005042,16.9915543 C18.0005042,16.7333922 17.9016765,16.4752301 17.7050296,16.2785831 L13.4261944,11.9997479 Z" />
    </svg>
}

export const ThumbsDownIcon = ({ id }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="cxco-c-feedback__icon  cxco-c-feedback__icon--small  cxco-c-feedback__icon--down">
        <defs>
            <linearGradient id={`${id}-down`} x1="0%" y1="0%" x2="0%" y2="100%">
                <stop class="cxco-c-gradient-start" offset="0%" stop-color="#00bc9b" />
                <stop class="cxco-c-gradient-end" offset="100%" stop-color="#5eaefd" />
            </linearGradient>
        </defs>
        <title>Icons/Thumbs Down</title>
        <g id="Icons/Thumbs-Down" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M13.5,2 C15.9142733,2 17.8844891,3.90123652 17.995102,6.2881643 L18,6.5 L18,9 L19,9 C20.5976809,9 21.9036609,10.24892 21.9949073,11.8237272 L22,12 L22,15 C22,18.2383969 19.4344251,20.8775718 16.2249383,20.9958615 L16,21 L8.73181186,21.0010775 C8.38584934,21.5982846 7.7398375,22 7,22 L7,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,20 L2,11 C2,9.8954305 2.8954305,9 4,9 L4,9 L7,9 C7.74025244,9 8.38657394,9.40216612 8.73239368,9.99992752 L10,10 C10.5128358,10 10.9355072,9.61395981 10.9932723,9.11662113 L11,9 L11,3 C11,2.48716416 11.3860402,2.06449284 11.8833789,2.00672773 L12,2 L13.5,2 Z M7,11 L4,11 L4,20 L7,20 L7,11 Z M13.5,4 L13,4 L13,9 C13,10.5976809 11.75108,11.9036609 10.1762728,11.9949073 L10,12 L9,12 L9,19 L16,19 C18.1421954,19 19.8910789,17.3160315 19.9951047,15.1996403 L20,15 L20,12 C20,11.4871642 19.6139598,11.0644928 19.1166211,11.0067277 L19,11 L18,11 C16.9456382,11 16.0818349,10.1841222 16.0054857,9.14926234 L16,9 L16,6.5 C16,5.1745166 14.9684641,4.08996133 13.6643757,4.00531768 L13.5,4 Z" id="🎨-Icon-color" fill={`url(#${id}-down)`} transform="translate(12.000000, 12.000000) scale(1, -1) translate(-12.000000, -12.000000) " />
        </g>
    </svg>
)

export const ThumbsUpIcon = ({ id }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="cxco-c-feedback__icon  cxco-c-feedback__icon--small  cxco-c-feedback__icon--up">
        <defs>
            <linearGradient id={`${id}-up`} x1="0%" y1="0%" x2="0%" y2="100%">
                <stop class="cxco-c-gradient-start" offset="0%" stop-color="#00bc9b" />
                <stop class="cxco-c-gradient-end" offset="100%" stop-color="#5eaefd" />
            </linearGradient>
        </defs>
        <title>Icons/Thumbs Up</title>
        <g id="Icons/Thumbs-Up" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M13.5,2 C15.9142733,2 17.8844891,3.90123652 17.995102,6.2881643 L18,6.5 L18,9 L19,9 C20.5976809,9 21.9036609,10.24892 21.9949073,11.8237272 L22,12 L22,15 C22,18.2383969 19.4344251,20.8775718 16.2249383,20.9958615 L16,21 L8.73181186,21.0010775 C8.38584934,21.5982846 7.7398375,22 7,22 L7,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,20 L2,11 C2,9.8954305 2.8954305,9 4,9 L4,9 L7,9 C7.74025244,9 8.38657394,9.40216612 8.73239368,9.99992752 L10,10 C10.5128358,10 10.9355072,9.61395981 10.9932723,9.11662113 L11,9 L11,3 C11,2.48716416 11.3860402,2.06449284 11.8833789,2.00672773 L12,2 L13.5,2 Z M7,11 L4,11 L4,20 L7,20 L7,11 Z M13.5,4 L13,4 L13,9 C13,10.5976809 11.75108,11.9036609 10.1762728,11.9949073 L10,12 L9,12 L9,19 L16,19 C18.1421954,19 19.8910789,17.3160315 19.9951047,15.1996403 L20,15 L20,12 C20,11.4871642 19.6139598,11.0644928 19.1166211,11.0067277 L19,11 L18,11 C16.9456382,11 16.0818349,10.1841222 16.0054857,9.14926234 L16,9 L16,6.5 C16,5.1745166 14.9684641,4.08996133 13.6643757,4.00531768 L13.5,4 Z" id="🎨-Icon-color" fill={`url(#${id}-up)`}/>
        </g>
    </svg>
)

export const StepOver = () => (<svg xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24">
    <title>
        Step over
    </title>
    <defs>
        <linearGradient id="stepovergradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stop-color="#000000" class="cxco-c-gradient-start"></stop>
            <stop offset="100%" stop-color="#ffffff" class="cxco-c-gradient-end"></stop>
        </linearGradient>
    </defs>
    <path fill="url(#stepovergradient)" fill-rule="evenodd" d="M19,8 C18.448,8 18,8.447 18,9 L18,10.724 C16.49,9.011 14.302,8 12,8 C7.589,8 4,11.589 4,16 C4,16.553 4.448,17 5,17 C5.552,17 6,16.553 6,16 C6,12.691 8.691,10 12,10 C13.708,10 15.333,10.74 16.465,12 L15,12 C14.448,12 14,12.447 14,13 C14,13.553 14.448,14 15,14 L19,14 C19.552,14 20,13.553 20,13 L20,9 C20,8.447 19.552,8 19,8" />
</svg>)

export const Error = ({ id }) => (<svg xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24">
    <title>
        Step over
    </title>
    <defs>
        <linearGradient id={`${id}-error`} x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stop-color="#000000" class="cxco-c-gradient-start"></stop>
            <stop offset="100%" stop-color="#ffffff" class="cxco-c-gradient-end"></stop>
        </linearGradient>
    </defs>
    <path fill={`url(#${id}-error)`} fill-rule="evenodd" d="M16.707,15.293 C16.902,15.488 17,15.744 17,16 C17,16.256 16.902,16.512 16.707,16.707 C16.512,16.902 16.256,17 16,17 C15.744,17 15.488,16.902 15.293,16.707 L12,13.414 L8.707,16.707 C8.512,16.902 8.256,17 8,17 C7.744,17 7.488,16.902 7.293,16.707 C7.098,16.512 7,16.256 7,16 C7,15.744 7.098,15.488 7.293,15.293 L10.586,12 L7.293,8.707 C7.098,8.512 7,8.256 7,8 C7,7.744 7.098,7.488 7.293,7.293 C7.684,6.902 8.316,6.902 8.707,7.293 L12,10.586 L15.293,7.293 C15.684,6.902 16.316,6.902 16.707,7.293 C16.902,7.488 17,7.744 17,8 C17,8.256 16.902,8.512 16.707,8.707 L13.414,12 L16.707,15.293 Z M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.519,22 22,17.52 22,12 C22,6.48 17.519,2 12,2 L12,2 Z" />
</svg>)

export const Success = ({ id }) => (<svg xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24">
    <title>
        Step over
    </title>
    <defs>
        <linearGradient id={`${id}-success`} x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stop-color="#000000" class="cxco-c-gradient-start"></stop>
            <stop offset="100%" stop-color="#ffffff" class="cxco-c-gradient-end"></stop>
        </linearGradient>
    </defs>
    <path fill={`url(#${id}-success)`} fill-rule="evenodd" d="M17.753,8.659 L10.753,16.659 C10.57,16.867 10.31,16.991 10.033,17 C10.023,17 10.011,17 10,17 C9.735,17 9.481,16.895 9.293,16.707 L5.293,12.707 C4.902,12.317 4.902,11.684 5.293,11.293 C5.684,10.903 6.316,10.903 6.707,11.293 L9.951,14.537 L16.247,7.342 C16.611,6.925 17.243,6.884 17.658,7.248 C18.074,7.612 18.116,8.243 17.753,8.659 M12,2 C6.481,2 2,6.48 2,12 C2,17.52 6.481,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2" />
</svg>)

export const EditIcon = ({ title = 'Edit', id = Math.random().toString(36).substr(2, 9) }) => (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <defs>
        <linearGradient id={`${id}-edit`} x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stop-color="#000000" class="cxco-c-gradient-start"></stop>
            <stop offset="100%" stop-color="#ffffff" class="cxco-c-gradient-end"></stop>
        </linearGradient>
    </defs>
    <title>
        {title}
    </title>
    <path fill={`url(#${id}-edit)`} fill-rule="evenodd" d="M14.793,5.793 L18.207,9.207 L7.707,19.707 C7.597,19.816 7.463,19.899 7.316,19.948 L4.316,20.948 C4.212,20.983 4.106,21 4,21 C3.739,21 3.483,20.897 3.293,20.707 C3.025,20.439 2.932,20.043 3.051,19.684 L4.051,16.684 C4.101,16.536 4.183,16.402 4.293,16.293 L14.793,5.793 Z M15.3785729,9.207 L14.792998,8.62142514 L5.87357827,17.5408199 L5.58115144,18.4180977 L6.46063697,18.1249359 L15.3785729,9.207 Z M20.707,5.293 C21.098,5.684 21.098,6.316 20.707,6.707 L19.207,8.207 L15.793,4.793 L17.293,3.293 C17.684,2.902 18.316,2.902 18.707,3.293 L20.707,5.293 Z" />
</svg>)

export const LanguageIcon = ({ title = 'Language', id = Math.random().toString(36).substr(2, 9), className = '' }) => (<svg class={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <title>{title}</title>
    <defs>
        <linearGradient id={`${id}-language`} x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stop-color="#000000" class="cxco-c-gradient-start"></stop>
            <stop offset="100%" stop-color="#ffffff" class="cxco-c-gradient-end"></stop>
        </linearGradient>
    </defs>
    <path fill={`url(#${id}-language)`} fill-rule="evenodd" d="M4.06189375,13 C4.40436852,15.7464283 6.13898913,18.0605201 8.53730758,19.2138273 C7.70258476,17.6107265 7.14550397,15.4337025 7.02468762,13 L4.06189375,13 L4.06189375,13 Z M4.06189375,11 L7.02468762,11 C7.14550397,8.56629752 7.70258476,6.38927348 8.53730758,4.78617271 C6.13898913,5.93947993 4.40436852,8.2535717 4.06189375,11 L4.06189375,11 Z M19.9381062,11 C19.5956315,8.2535717 17.8610109,5.93947993 15.4626924,4.78617271 C16.2974152,6.38927348 16.854496,8.56629752 16.9753124,11 L19.9381062,11 L19.9381062,11 Z M19.9381062,13 L16.9753124,13 C16.854496,15.4337025 16.2974152,17.6107265 15.4626924,19.2138273 C17.8610109,18.0605201 19.5956315,15.7464283 19.9381062,13 L19.9381062,13 Z M9.02751198,13 C9.13838022,15.0038045 9.57904842,16.8280965 10.2533205,18.1766406 C10.8812109,19.4324214 11.5521641,20 12,20 C12.4478359,20 13.1187891,19.4324214 13.7466795,18.1766406 C14.4209516,16.8280965 14.8616198,15.0038045 14.972488,13 L9.02751198,13 L9.02751198,13 Z M9.02751198,11 L14.972488,11 C14.8616198,8.99619554 14.4209516,7.17190349 13.7466795,5.82335938 C13.1187891,4.56757856 12.4478359,4 12,4 C11.5521641,4 10.8812109,4.56757856 10.2533205,5.82335938 C9.57904842,7.17190349 9.13838022,8.99619554 9.02751198,11 L9.02751198,11 Z M12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 Z"/>
</svg>)

export const OpenVerticalIcon = ({ title = 'OpenVertical', id = Math.random().toString(36).substr(2, 9), className = '' }) => (<svg class={`${className}`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <title>{title}</title>
    <defs>
        <linearGradient id={`${id}-openvertical`} x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stop-color="#000000" class="cxco-c-gradient-start"></stop>
            <stop offset="100%" stop-color="#ffffff" class="cxco-c-gradient-end"></stop>
        </linearGradient>
    </defs>
    <path fill={`url(#${id}-openvertical)`} fill-rule="evenodd" d="M12.00025,15.00025 C11.74425,15.00025 11.48825,14.90225 11.29325,14.70725 L6.29325,9.70725 C5.90225,9.31625 5.90225,8.68425 6.29325,8.29325 C6.68425,7.90225 7.31625,7.90225 7.70725,8.29325 L12.00025,12.58625 L16.29325,8.29325 C16.68425,7.90225 17.31625,7.90225 17.70725,8.29325 C18.09825,8.68425 18.09825,9.31625 17.70725,9.70725 L12.70725,14.70725 C12.51225,14.90225 12.25625,15.00025 12.00025,15.00025"/>
</svg>)

export const ResetIcon = ({ title = 'Reset' }) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <title>
            {title}
        </title>
        <defs>
            <linearGradient id='restart' x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stop-color="#000000" class="cxco-c-gradient-start"></stop>
                <stop offset="100%" stop-color="#ffffff" class="cxco-c-gradient-end"></stop>
            </linearGradient>
        </defs>
        <path fill={'url(#restart)'} fill-rule="evenodd" d="M6,8.781 L6,7 C6,6.447 5.553,6 5,6 C4.447,6 4,6.447 4,7 L4,11 C4,11.553 4.447,12 5,12 L9,12 C9.553,12 10,11.553 10,11 C10,10.447 9.553,10 9,10 L7.616,10 C8.553,8.17 10.422,7 12.5,7 C15.532,7 18,9.468 18,12.5 C18,15.532 15.532,18 12.5,18 C11.947,18 11.5,18.447 11.5,19 C11.5,19.553 11.947,20 12.5,20 C16.636,20 20,16.636 20,12.5 C20,8.364 16.636,5 12.5,5 C9.782,5 7.325,6.463 6,8.781 Z" />
    </svg>
}
