const { SELECTED_LANGUAGE, SET_CONTEXT } = require('./socket').MESSAGE_TYPES
const debug = require('debug')('wcc:utils')

/**
 * Emits the event to set the language and returns whether it succeeded or not
 * @param {Object} socket
 * @param {String} languageCode the language code
 * @returns {boolean} true/false depending on success or not
 */
export const sendSetSelectedLanguage = async (socket, languageCode) => {
    try {
        if (!languageCode) throw new Error('languageCode is undefined')

        return await new Promise(resolve => {
            socket.emit(SELECTED_LANGUAGE, languageCode, (response) => {
                resolve(response)
            })
        })
    } catch (error) {
        debug(error)
        console.error(error)

        return false
    }
}

/**
 * Emits the event to set the context and returns whether it succeeded or not
 * @param {Object} socket
 * @param {String} contextName the context name
 * @param {String} contextValue the context value
 * @returns {boolean} true/false depending on success or not
 */
export const sendSetContext = async (socket, contextName, contextValue) => {
    try {
        if (!contextName) throw new Error('contextName is undefined')
        if (!contextValue) throw new Error('contextValue is undefined')

        return await new Promise(resolve => {
            socket.emit(SET_CONTEXT, { contextName, contextValue }, (response) => {
                resolve(response)
            })
        })
    } catch (error) {
        debug(error)
        console.error(error)

        return false
    }
}
