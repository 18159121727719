/* eslint-disable no-unused-vars */
import { h } from 'hyperapp'

export const Titlebar = ({ title, subtitle }) => (state, actions) => {
    return (
        <cxco-c-titlebar class="cxco-c-titlebar">
            <h2 class="cxco-c-titlebar__title" role="heading" aria-level="2">{title}</h2>
            <SubTitle subtitle={subtitle} />
        </cxco-c-titlebar>
    )
}

const SubTitle = ({ subtitle }) => (state, actions) => {
    if (!state.agentName) {
        return <h3 class="cxco-c-titlebar__subtitle" role="heading" aria-level="3">{subtitle}</h3>
    }
}
