import Socket from './socket'
import Eventstop from 'eventstop'
import Hook from 'before-after-hook'
import WccStorage from './storage'
import { window } from 'global/window'
import WccWindowApi from './windowApi'
import { BroadcastChannel } from 'broadcast-channel'
const { sendSetSelectedLanguage } = require('./utils')
const debug = require('debug')('wcc:core')

/**
 * WccCore - the main class to be instantiated.
 * Exposes methods to perform actions (ask, sendEvent, etc...) and allows plugins to listen and modify them.
 * @param {String} wccId - The configuration id
 * @param {Boolean} isPreviewMode - Specifies the preview mode
 */
export default class WccCore {
    constructor (wccId, isPreviewMode) {
        this.isPreviewMode = isPreviewMode
        this.hook = new Hook.Collection()
        this.eventstop = new Eventstop()
        this.on = this.eventstop.on
        this.emit = this.eventstop.emit
        this.before = this.hook.before
        this.after = this.hook.after
        this.wccId = wccId
        this.config = {}
        this.storage = new WccStorage(this.wccId, this.isPreviewMode)
        this.socket = this.initSocketApi()
        this.windowApi = new WccWindowApi(this)
        this.broadcastChannel = new BroadcastChannel(window.wcc.channelName ??= `wcc_channel_${Date.now()}`)
        this.broadcastChannel.onmessage = ({ wccId, ...requestPayload }) => {
            if (wccId === this.wccId) return

            if (requestPayload.data.metadata?.inputLanguage) {
                // Overwrite the request payload inputLanguage to use the language of its own state instance
                requestPayload.data.metadata.inputLanguage = this.storage.get()?.selectedLanguage?.languageCode
            }

            this.socket.emit('request', requestPayload)
        }
        this.broadcastChannel.onmessageerror = ({ data }) => debug(data)
    }

    initSocketApi () {
        const server = process.env.WS_SERVER
        let UID

        const localConfig = this.storage.get('CONFIG')

        if (Object.entries(localConfig).length > 0 && localConfig.constructor === Object) {
            UID = localConfig.UID
        }

        return new Socket(this, {
            server: server,
            CONFIGKEY: this.wccId,
            UID
        })
    }

    use (plugin) {
        plugin.install(this)
    }

    /**
   * Asks a question. Emits a 'request' event on the socket.
   * @param {Object} askPayload
   */
    ask (askPayload, hideInUI) {
        askPayload = {
            data: {
                userInput: typeof askPayload === 'string' ? askPayload : askPayload.data.userInput
            },
            metadata: {
                hideInUI,
                ...askPayload.metadata
            }
        }

        askPayload.type = 'ask'
        askPayload = this.addMetadataToPayload(askPayload)

        const that = this

        return this.hook('ask', function (ask) {
            that.socket.emit('request', askPayload)

            if (!askPayload.wccId) {
                that.broadcastChannel.postMessage({ ...askPayload, wccId: that.wccId })
            }

            return ask
        }, askPayload)
    }

    dialogStep (dsPayload) {
        if (typeof dsPayload === 'string') {
            dsPayload = {
                data: {
                    dialogPath: dsPayload
                }
            }
        }
        dsPayload.type = 'dialogstep'
        dsPayload = this.addMetadataToPayload(dsPayload)

        this.socket.emit('request', dsPayload)
    }

    /**
   * Send Event to API. Emits an core 'event' event.
   * @param {*} eventPayload
   */
    sendEvent (eventPayload) {
        if (typeof eventPayload === 'string') {
            eventPayload = {
                data: {
                    eventName: eventPayload
                }
            }
        }
        eventPayload.type = 'event'
        eventPayload = this.addMetadataToPayload(eventPayload)

        debug('sending event ' + eventPayload.data.eventName)
        this.socket.emit('request', eventPayload)
    }

    /**
     * Emits a core 'linkclick' event.
     * @param {Object} payload - e.g. {
                data: { interactionId, linkUrl, linkId}
            }
     */
    sendLinkClick (payload) {
        payload.type = 'linkclick'
        this.socket.emit('request', payload)
    }

    /**
   * Emits a core 'feedback' event.
   * @param {Object} payload
   */
    sendFeedback (payload) {
        payload.type = 'feedback'
        this.socket.emit('request', payload)
    }

    /**
   * Emits core 'answer' event.
   * Uses hook to allow for before/after `core.before('answer', callback)` operations.
   * @param {Object} answerPayload
   */
    answer (answerPayload) {
        const that = this

        const ctaDelay = this.getCTADelay(answerPayload)

        return this.hook('answer', function (answer) {
            if (ctaDelay > 0) {
                setTimeout(() => {
                    that.emit('answer', answer)
                }, ctaDelay)
            } else {
                that.emit('answer', answer)
            }

            return answer
        }, answerPayload)
    }

    /**
   * Adds original API request to the answer and creates/updates session id.
   * Uses hook to allow for before/after `core.before('request', callback)` operations.
   * Emits core 'request' event.
   * @param {Object} requestPayload
   */
    request (requestPayload) {
        const that = this

        return this.hook('request', function (request) {
            that.emit('request', request)

            return request
        }, requestPayload)
    }

    /**
   * Emits core 'faq' event to retrieve
   * the answer to a specific FAQ identified by `faqId`.
   * @param {Object} payload
   */
    faqAsk ({ id, question, isNewTopic, setDividerBefore }) {
        let payload = {
            data: {
                faqId: id,
                userInput: question
            },
            type: 'faq'
        }

        payload = this.addMetadataToPayload(payload, { isNewTopic, setDividerBefore })

        this.socket.emit('request', payload)
    }

    /**
   * Emits core 'categorytree' event
   * @param {Object} payload
   */
    getAllFaqs (payload) {
        payload.type = 'categorytree'
        payload = this.addMetadataToPayload(payload)

        this.socket.emit('request', payload)
    }

    /**
   * Emits core 'faqsincategory' event
   * @param {Object} payload
   */
    getFaqsByCategoryId (payload) {
        payload.type = 'faqsincategory'
        payload = this.addMetadataToPayload(payload)

        this.socket.emit('request', payload)
    }

    emitCustomEvent (eventName, message) {
        this.emit(eventName, message)
    }

    changeState (statePayload) {
        debug('ui.changeState', statePayload)
        this.emit('ui.changeState', statePayload)
    }

    upsertConfig (config, expiredUser) {
        try {
            if (expiredUser) {
                // clear the local storage state if the user is expired
                this.storage.store({}, 'STATE')
            }

            this.config = config
            // when there is a valid window config object and it doesn't specify to lazy load then start immediately.
            if (this.config && (typeof this.config.lazyLoad === 'undefined' || (typeof this.config.lazyLoad === 'boolean' && !this.config.lazyLoad))) {
                this.emit('ui.init')
                // If its a number use a timeout
            } else if (typeof this.config.lazyLoad === 'number') {
                setTimeout(() => this.emit('ui.init'), this.config.lazyLoad)
            }
        } catch (error) {
            debug('failed to initialize', config, error)
        }
    }

    stepOver () {
        this.socket.emit('request', { type: 'stepover' })
    }

    setFeedbackInteractionId (feedbackInteractionId) {
        this.emit('ui.changeState', { feedbackInteractionId })
    }

    /**
   * Emits event to archive conversation.
   */
    archive () {
        debug('ui.archive')
        this.emit('ui.archive')
    }

    /**
   * Update and reload configuration
   */
    updateConfig () {
        this.socket.emit('update_config', {})
    }

    /**
   * Adds extra metadata to payload
   * @param {Object} payload
   * @param {Object} metadata
   * @returns
   */
    addMetadataToPayload (payload, metadata) {
        payload.metadata = {
            ...payload.metadata,
            ...metadata,
            lastInteraction: this.storage.get()?.lastInteraction,
            referer: window.location.href,
            userAgent: window.navigator.userAgent,
            origin: 'ui' // Filter this 'ui' message on sendPendingMessages at server
        }

        return payload
    }

    /**
   *  Gets the ctaDelay from the payload, if it doesn't exist, returns 0
   * @param {Object} payload
   * @returns {Number}
   */
    getCTADelay (payload) {
        let ctaDelay = Number(payload.data?.outputAdditions?.ctaDelay)

        ctaDelay = isNaN(ctaDelay) ? 0 : ctaDelay

        return ctaDelay
    }

    /**
   * Emit changeState with the supported languages
   * @param {Object} supportedLanguages
   */
    setSupportedLanguages (supportedLanguages) {
        if (!supportedLanguages?.translation) return

        const originalLanguage = this.config.originalLanguage

        const supportedLanguagesList = Object.entries(
            supportedLanguages.translation
        ).map(([languageCode, language]) => ({
            languageCode,
            ...language,
            isActive: languageCode === originalLanguage,
            nativeName: language.nativeName,
            originalLanguage
        }))

        const selectedLanguage = supportedLanguagesList.find(
            ({ languageCode }) => languageCode === originalLanguage
        )

        this.emit('ui.changeState', {
            supportedLanguages: supportedLanguagesList,
            filteredLanguages: supportedLanguagesList,
            selectedLanguage: selectedLanguage?.languageCode
        })
    }

    /**
     * Emits the selected languageCode and emit an answer to show the language has switched
     * @param {Object} selectedLanguage
     */
    async setSelectedLanguage (selectedLanguage) {
        return sendSetSelectedLanguage(this.socket, selectedLanguage.languageCode)
    }

    /**
     * Emits to update the state after reset
     * @param {Object} user new user
     */
    resetConversation (user) {
        this.emit('ui.reset', user)
    }
}
