/**
 * Simple swipe behaviour
 * @param {HTMLElement} elm
 */

export const initSwipe = (elements) => {
    elements.forEach(element => {
        let initialX = null
        let initialScroll = 0
        let locked = false

        const unifyEv = (ev) => ev.changedTouches ? ev.changedTouches[0] : ev

        const lock = (ev) => {
            initialX = unifyEv(ev).clientX
            initialScroll = element.scrollLeft
            locked = true
        }

        const move = (ev) => {
            ev.preventDefault()
            if (locked) {
                initialX = null
                initialScroll = 0
                locked = false
                element.children.forEach(childElm => { childElm.style = '' })
            }
        }

        const drag = (ev) => {
            ev.preventDefault()
            if (locked) {
                const difference = unifyEv(ev).clientX - initialX

                element.scrollLeft = initialScroll - difference
                element.children.forEach(childElm => { childElm.style = 'pointer-events: none;' })
            }
        }

        const leave = (ev) => {
            locked = false
            element.children.forEach(childElm => { childElm.style = '' })
        }

        element.addEventListener('mousedown', lock, false)
        element.addEventListener('touchstart', lock, false)
        element.addEventListener('mouseup', move, false)
        element.addEventListener('touchend', move, false)
        element.addEventListener('mousemove', drag, false)
        element.addEventListener('touchmove', drag, false)
        element.addEventListener('mouseleave', leave, false)
    })
}
