/**
 * import and use the packages to be bundled as the WCC.
 */
import WccCore from '../wcc-core/src/'
import WccUI, { htmlAnswers } from '../wcc-ui/src'
import ikb from '../wcc-ui/src/plugins/ikb'
import { createCustomElementName } from '../wcc-ui/src/utils'
import { getStateSettings } from '../wcc-ui/src/state'
import languageDetection from '../wcc-ui/src/plugins/languageDetection'
const debug = require('debug')('wcc:wcc-widget')

let fonts = ''

try {
    fonts = require('../wcc-ui/src/scss/3-Elements/_elements.fonts.scss').default[0][1]
} catch (error) {
    console.error('default fonts not found')
}

/**
 * @param {Object} core instance of a WccCore
 * @extends HTMLElement extends the default HTMLElement Class to be used as Web Component
 * @returns WccWebComponent Class declaration
 */
export const createdWccWebComponent = core => class WccWebComponent extends HTMLElement {
    constructor () {
        super()
        this.attachShadow({ mode: 'open' })
        const wrapper = document.createElement('cxco-root')

        wrapper.setAttribute('class', 'cxco')
        this.shadowRoot.append(wrapper)
        core.use(htmlAnswers)
        core.use(languageDetection)
        core.use(WccUI(wrapper))
    }

    connectedCallback () {
        debug(`Chat ${core.wccId} created`)
    }

    disconnectedCallback () {
        debug(`Chat ${core.wccId} destroyed`)
    }
}

/**
 * Adds a style tag outside the shadowDOM to use fonts
 * @param {HTMLElement} parentElement The parent element to mount on
 * @param {string}  fonts The default fonts as CSS inline style string
 * @returns
 */
export const addFonts = parentElement => fonts => {
    const styleNode = document.createElement('style')

    styleNode.innerText = fonts
    parentElement.insertAdjacentElement('afterbegin', styleNode)
}

/**
 * Curried callback fn to init an UI instance, or reload the state of and existing one
 * @param {*} core instance of a WccCore
 */
export const initUI = core => () => {
    const parentElement = getParentElement(core)
    const existingInstance = window.wcc.instances.get(core.wccId)
    const mountedInstance = parentElement.contains(existingInstance)

    core.use(ikb(parentElement, window.wcc.cores))

    // if instance already exists don't reinitialize
    // reset and save state
    if (mountedInstance) {
        core.changeState(getStateSettings(core.config))

        return
    }

    createNewInstance(core)(parentElement)
}

/**
 * Initiates a Wcc Core and mounts the UI on the DOM
 * @param {string} wccId The wcc instance id
 */
export const initiateWccCore = (wccId) => {
    const isPreviewMode = !!document.querySelector('[data-web-conversations-preview]')

    if (!wccId) {
        console.error('Cannot initiate a Wcc Core, because no configuration ID was provided')

        return
    }

    if (window.wcc.cores.has(wccId) && window.wcc.instances.has(wccId)) {
        window.wcc.cores.get(wccId).emit('ui.init')
    }

    if (window.wcc.cores.has(wccId)) return

    const core = new WccCore(wccId, isPreviewMode)

    window.wcc.cores.set(wccId, core)

    core.on('ui.init', initUI(core))

    return core
}

/**
 * Creates a new instance
 * @param {Object} core instance of a WccCore
 * @param {Element} parentElement The parent element to mount on
 */
export const createNewInstance = core => parentElement => {
    // In the builder it is possible to navigate to different configs with the same wccId
    // For example navigating from chat config with id 1 to ikb config with chat id 1 and chat id 2
    // Therefore we need to have unique names for all new instances
    const customElementName = createCustomElementName(core.wccId)
    let createdInstance

    if (!customElements.get(customElementName)) {
        customElements.define(customElementName, createdWccWebComponent(core))
    }

    addFonts(parentElement)(fonts)
    parentElement.setAttribute('data-wcc-id', core.wccId)
    parentElement.setAttribute('data-wcc-wrapper', '')

    if (!window.wcc.instances.has(core.wccId)) {
        createdInstance = document.createElement(customElementName)
        window.wcc.instances.set(core.wccId, createdInstance)
    }
    createdInstance = window.wcc.instances.get(core.wccId)

    // Expand root container for inline
    createdInstance.style = 'width: 100%; height: 100%;'
    parentElement.appendChild(createdInstance)

    if (typeof core.config?.style === 'string' && core.config.style !== '') {
        core.emit('ui.updateStyle', {
            id: 'config',
            cssText: core.config.style
        })
    }

    if (typeof core.config?.customStyle === 'string' && core.config.customStyle !== '') {
        core.emit('ui.updateStyle', {
            id: 'custom',
            cssText: core.config.customStyle
        })
    }
}

/**
 * Gets Parent Element where the widget will attach to, or creates a new root
 * @param {String} wccId The config id
 * @param {Object} config The core config
 * @returns {HTMLElement|null} HTMLElement or null
 */
export const getParentElement = ({ wccId, config }) => {
    const existingInstance = document.querySelector(`[data-wcc-id="${wccId}"]`)
    const IKBSelector = document.querySelector('[data-wcc-ikb]')
    const previewModeElement = document.querySelector('[data-web-conversations-preview]')

    if (previewModeElement) {
        return previewModeElement
    }

    // return ikb parentSelector if possible
    if (IKBSelector) {
        return IKBSelector
    }

    // First check for existing instances and return the specific parent node
    if (existingInstance) {
        return existingInstance
    }

    // return parentSelector if defined through config
    if (config && config.parentSelector) {
        return document.querySelector(config.parentSelector)
    }

    // Return document as default node
    const newParentElement = document.createElement('div')

    return document.body.appendChild(newParentElement)
}

/**
 * The main init function
 */
export const init = () => {
    try {
        window.wcc ??= {}
        window.wcc.instances ??= new Map()
        window.wcc.cores ??= new Map()
        window.wcc.init ??= (wccId) => initiateWccCore(wccId)

        for (const [key, value] of Object.entries(window.wcc)) {
            // Check if the value is an event queue or either an instance copy of the WccCore

            if (value && (Array.isArray(value))) {
                initiateWccCore(key)
            }
        }
    } catch (error) {
        debug(error)
    }
}

init()
