/* eslint-disable no-unused-vars */
import { h } from 'hyperapp'

export const Loader = () => (state, actions) => {
    return (
        <ul class={'cxco-c-loader'} data-state={state.isLoading ? 'loading' : ''}>
            <li class="cxco-c-loader__bubble"></li>
            <li class="cxco-c-loader__bubble"></li>
            <li class="cxco-c-loader__bubble"></li>
        </ul>
    )
}
