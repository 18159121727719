const marked = require('marked')

marked.setOptions({
    gfm: true,
    tables: true,
    breaks: true,
    sanitize: false,
    smartLists: true,
    headerPrefix: 'cxco'
})

const parser = new marked.Parser()

/**
 * Recieves a marked token Object and creates a similar token list
 * to hydrate the view
 * @param {*} param0
 * @param {*} commonMessagePayload
 */

// This is the lexer that determines the current nodes Media Type
export const lexer = ({ data, metadata }, commonMessagePayload) => (
    accumulator,
    id,
    idx,
    token
) => {
    if (!token) return

    const { isInline, text } = token
    const { images, links, videos, ogs, mediaBlocks } = data
    const imagePattern = /%{Image\((.*?)\)}/gi
    const videoPattern = /%{Video\((.*?)\)}/gi
    const linkPattern = /%{Link\((.*?)\)}/gi
    const mediaBlockPattern = /%{MediaBlock\((.*?)\)}/gi
    const evalCommonMessagePayload = (isInline ? {} : commonMessagePayload(id, idx))

    if (linkPattern.test(text)) {
        const linkPatterns = new RegExp('%\\{Link\\((.*?)\\)\\}', 'gi')
        const matches = text.matchAll(linkPatterns)

        for (const [, linkId] of matches) {
            const link = links.find((link) => link.id === parseInt(linkId))

            // The first link needs to be rendered as a linkpreview if ogs data is available
            if (ogs && ogs?.ogImage && parseInt(linkId) === 1) {
                const { ogImage, ogTitle, ogDescription } = ogs
                const { height = 300, width = 300, url: ogImageUrl } = ogImage
                const mediaBlockPayload = {
                    ...evalCommonMessagePayload,
                    mediaType: 'card',
                    link,
                    image: ogImageUrl,
                    title: ogTitle,
                    description: ogDescription,
                    height,
                    width
                }

                accumulator.push(mediaBlockPayload)
            } else {
                const linkPayload = {
                    ...evalCommonMessagePayload,
                    ...link,
                    mediaType: 'link'
                }

                accumulator.push(linkPayload)
            }
        }
    } else if (Array.isArray(mediaBlocks) && mediaBlockPattern.test(text)) {
        const mediaBlockPatterns = new RegExp('%\\{MediaBlock\\((.*?)\\)\\}', 'gi')
        const matches = text.matchAll(mediaBlockPatterns)

        for (const [, mediaBlockId] of matches) {
            const mediaBlockPayload = {
                ...evalCommonMessagePayload,
                ...mediaBlocks[mediaBlockId]
            }

            accumulator.push(mediaBlockPayload)
        }
    } else if (Array.isArray(videos) && videoPattern.test(text)) {
        const videoPatterns = new RegExp('%\\{Video\\((.*?)\\)\\}', 'gi')
        const matches = text.matchAll(videoPatterns)

        for (const [, vidId] of matches) {
            const { source, additionalData } = videos.find(
                (vid) => vid.id === parseInt(vidId)
            )
            const { videoWidth, videoAlign, videoId } = additionalData

            // set the proper embed url based on the source
            var url = ''

            if (source.toLowerCase() === 'youtube') {
                url = 'https://www.youtube.com/embed/' + videoId
            } else if (source.toLowerCase() === 'youtubenocookie') {
                url = 'https://www.youtube-nocookie.com/embed/' + videoId
            } else if (source.toLowerCase() === 'vimeo') {
                url = 'https://player.vimeo.com/video/' + videoId
            }

            const videoPayload = {
                ...evalCommonMessagePayload,
                mediaType: 'video',
                url,
                alignment: videoAlign || 'left',
                width: videoWidth
            }

            accumulator.push(videoPayload)
        }
    } else if (Array.isArray(images) && imagePattern.test(text)) {
        const imagePatterns = new RegExp('%\\{Image\\((.*?)\\)\\}', 'gi')
        const matches = text.matchAll(imagePatterns)

        for (const [, imgId] of matches) {
            const { name: url, title, linkId, additionalData } = images.find(
                (img) => img.id === parseInt(imgId)
            )
            const { imageWidth, imageAlign } = additionalData

            const imagePayload = {
                ...evalCommonMessagePayload,
                mediaType: 'image',
                url,
                title,
                width: imageWidth ? parseFloat(imageWidth) * 100 : 100,
                alignment: imageAlign || 'left',
                link: links.find((l) => l.id === linkId)
            }

            accumulator.push(imagePayload)
        }
    } else {
        const textPayload = {
            ...evalCommonMessagePayload,
            mediaType: 'paragraph',
            text: token.type ? (isInline ? parser.parseInline([token]) : parser.parse([token])) : undefined,
            isNewTopic: idx === 0 ? metadata.isNewTopic : false
        }

        accumulator.push(textPayload)
    }

    return accumulator
}
