import io from 'socket.io-client'
const debug = require('debug')('wcc:core/socket')

export const MESSAGE_TYPES = {
    NEW_USER: 'new_user',
    REQ_USER: 'request_user',
    VAL_USER: 'validate_user',
    RCR_USER: 'recurring_user',
    TIME: 'time',
    STATE: 'state',
    RESPONSE: 'response',
    CONFIG: 'config',
    REQUEST: 'request',
    CONNECT: 'connect',
    DISCONNECT: 'disconnect',
    ERROR: 'error',
    WARN: 'warn',
    FEEDBACK_INTERACTION_ID: 'send_feedback_interactionId',
    RECONNECT: 'reconnect',
    SUPPORTED_LANGUAGES: 'supported_languages',
    SELECTED_LANGUAGE: 'selected_language',
    GET_CURRENT_LANGUAGE: 'get_current_language',
    RESET: 'reset',
    LEAVE: 'leave',
    SET_CONTEXT: 'set_context'
}

export default class Socket {
    constructor (core, options) {
        this.core = core

        this.server = options.server
        this.CONFIGKEY = options.CONFIGKEY
        this.UID = options.UID
        this.socket = io(this.server, { transports: ['websocket'] })
        this.on = this.socket.on.bind(this.socket)
        this.emit = this.socket.emit.bind(this.socket)
        // new user message comes in.
        this.socket.on(MESSAGE_TYPES.NEW_USER, function (user) {
            core.storage.store(user, 'CONFIG')
        })

        // known user message comes in.
        this.socket.on(MESSAGE_TYPES.RCR_USER, function (user) {
            core.storage.store(user, 'CONFIG')
        })

        // send user to the server
        this.socket.on(MESSAGE_TYPES.REQ_USER, () => {
            const CONF = {
                ...core.storage.get('CONFIG'),
                CONFIGKEY: this.CONFIGKEY,
                referer: window.location.href,
                userAgent: window.navigator.userAgent
            }

            this.socket.emit(MESSAGE_TYPES.VAL_USER, CONF)
        })

        // server replies
        this.socket.on(MESSAGE_TYPES.RESPONSE, function (response) {
            debug('ws server response', response)
            core.answer(response)
        })

        this.socket.on(MESSAGE_TYPES.REQUEST, function (response) {
            debug('ws server request', response)
            core.request(response)
        })

        this.socket.on(MESSAGE_TYPES.TIME, function (data) {
            debug(data.time)
        })

        this.socket.on(MESSAGE_TYPES.CONNECT, function () {
            debug('connected socket')
        })

        this.socket.on(MESSAGE_TYPES.DISCONNECT, function () {
            debug('disconnected socket')
        })
        this.socket.on(MESSAGE_TYPES.ERROR, function (err) {
            debug('Server error', err)
        })
        this.socket.on(MESSAGE_TYPES.WARN, function (err) {
            debug('Server warn', err)
        })

        this.socket.on(MESSAGE_TYPES.CONFIG, function ({ config, expiredUser }) {
            debug(MESSAGE_TYPES.CONFIG)
            // update state
            core.upsertConfig(config, expiredUser)
        })

        this.socket.on(MESSAGE_TYPES.FEEDBACK_INTERACTION_ID, function (interactionId) {
            core.setFeedbackInteractionId(interactionId)
        })

        this.socket.on(MESSAGE_TYPES.SUPPORTED_LANGUAGES, function (supportedLanguages) {
            core.setSupportedLanguages(supportedLanguages)
        })

        this.socket.on(MESSAGE_TYPES.RESET, function ({ newUser }) {
            core.resetConversation(newUser)
        })

        this.socket.io.on(MESSAGE_TYPES.RECONNECT, () => {
            this.socket.emit('reconnect')
        })
    }
}
