/* eslint-disable no-unused-vars */
import { h } from 'hyperapp'
import { Error, Success } from './icons'

export const Notify = ({ id, message, type, show, click }) => {
    if (typeof message === 'undefined' || message === undefined || message === '') return
    if (typeof show === 'undefined' || !show) return

    return (<cxco-c-notify class={`cxco-c-notify  cxco-c-notify--${type || ''}`}
        onclick={e => click()}>
        { type === 'success' ? <Success id={id}></Success> : <Error id={id}></Error> }
        {message}
    </cxco-c-notify>)
}
