/* eslint-disable no-unused-vars */
import { h } from 'hyperapp'
import { LanguageIcon, OpenVerticalIcon } from './icons'

export const Languages = () => (state, actions) => {
    if (state.translationEnabled && state.supportedLanguages && state.supportedLanguages.length > 0) {
        return (
            <section class="cxco-c-dropdown">
                <LanguagesButton />
                <LanguagesList />
            </section>
        )
    }
}

export const LanguagesList = () => (state, actions) => {
    if (
        state.showLanguagesList
        && state.filteredLanguages
        && state.filteredLanguages.length > 0
    ) {
        return (
            <section class={`cxco-c-dropdown__listwrapper  cxco-u-animation  ${state.showLanguagesList ? 'cxco-u-animation--fade-in' : ''}`} onfocusout={() => { actions.showLanguagesList(false) }} >
                <header class="cxco-c-dropdown__header">
                    <h6>Conversation language</h6>
                </header>
                <menu class="cxco-c-dropdown__list">
                    {state.filteredLanguages.map((language) => (
                        <li class="cxco-c-dropdown__item" key={language.languageCode}>
                            <button
                                class="cxco-c-dropdown__option"
                                data-state={language.isActive ? 'selected' : ''}
                                onfocusout={() => actions.showLanguagesList(false)}
                                onfocusin={() => actions.showLanguagesList(true)}
                                onclick={function (e) {
                                    actions.setSelectedLanguage({ selectedLanguage: language, shouldNotify: true })
                                    e.target.blur() // Remove the focus after selection
                                }}
                            >
                                {language.nativeName} {language.languageCode === language.originalLanguage ? '(original)' : ''}
                            </button>
                        </li>
                    ))}
                </menu>
            </section>
        )
    }
}

export const LanguagesButton = () => (state, actions) => {
    return (<button
        class={'cxco-c-dropdown__button'}
        aria-label="Select language"
        onclick={() => actions.showLanguagesList(!state.showLanguagesList)}
        onfocusout={() => { actions.showLanguagesList(false) }}
    >
        <LanguageIcon className={'cxco-c-dropdown__icon'} />
        <OpenVerticalIcon className={'cxco-c-dropdown__icon'} />
    </button>)
}
