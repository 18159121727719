/* eslint-disable no-unused-vars */
import { h } from 'hyperapp'

export const Action = () => (state, actions) => {
    if (state.chatLayout !== 'inline') {
        return <button
            class="cxco-c-action"
            onclick={actions.toggle}
            aria-expanded={state.isOpen}
        >
            <figure class="cxco-c-action__icon"></figure>
        </button>
    }
}
