
import { lexer } from './lexer'
import { hasDialogOptions, hasQuickReplies } from './utils'
const xssFilters = require('xss-filters')

const messagePayload = (messageGroupId, messageId) => ({
    id: `${messageGroupId}-${messageId}`,
    isHidden: true,
    timestamp: Date.now()
})

const messageGroupPayload = (id, type, options) => ({
    id,
    type,
    timestamp: Date.now(),
    messages: [],
    ...options
})

/**
 * Creates a new answer Message Group item
 * @param {string} id The current id/ index of the last message group
 * @param {Object} answer
 */
export const createAnswerMessageGroup = id => (answer, options = {}) => createMessageGroup(id, 'answer')(answer, options)

/**
 * Creates a new question Message Group item
 * @param {string} id The current id/ index of the last message group
 * @param {string} question
 */
export const createQuestionMessageGroup = id => (question, options = {}) => createMessageGroup(id, 'question')(question, options)

/**
 * Generates a messageGroup with messages to be rendered.
 * @param {Number} id The current id/ index of the last message group
 * @param {String} type Specifies wheter its a question or answer bubble
 * @param {Object | String} payload Answer or question payload
 * @param {Object} options Additional options
 */
export const createMessageGroup = (id, type) => (payload, options) => {
    id++

    // Create a default message group template
    let messageGroup = messageGroupPayload(id, type, options)

    if (type === 'question') {
        const textPayload = {
            ...messagePayload(id, 0),
            mediaType: 'paragraph',
            text: xssFilters.inHTMLData(payload),
            isHidden: false // TODO move logic
        }

        messageGroup.messages.push(textPayload)

        return messageGroup
    }

    if (type === 'answer') {
        const { data, metadata } = payload

        const createDialogOptions = (id, options, path, isChangeable = true, isActive = true) => ({
            messageGroupId: id,
            path,
            isChangeable,
            isActive,
            options: options.map((option, id) => ({
                id,
                text: option.text || option,
                isHidden: true,
                isSelected: false
            }))
        })

        let dialogOptions = {}

        // Add dialog options
        if (hasDialogOptions(data)) {
            dialogOptions = createDialogOptions(id, data.dialogOptions, metadata.dialogPath)
        }
        if (hasQuickReplies(metadata)) {
            dialogOptions = createDialogOptions(id, metadata.quickReplies, metadata.tDialogState, false)
        }

        messageGroup = {
            ...messageGroup,
            messageType: data.type,
            isCTAMessage: !!data?.outputAdditions?.ctaDelay,
            interactionId: metadata?.interactionId,
            sessionId: metadata?.sessionId,
            feedbackOptions: {},
            ...(typeof metadata.newTopic !== 'undefined' && { newTopic: metadata.newTopic }),
            isNewTopic: metadata.isNewTopic,
            dialogOptions,
            isParagraph: metadata?.isParagraph,
            setDividerBefore: metadata?.setDividerBefore
        }

        // Filter out the spaces
        data.answer = data.answer.filter(token => token.type !== 'space')

        // Add answer payload and message payload to the lexer once.
        const addToken = lexer(payload, messagePayload)

        // T-Dialog OK messages need a bubble of their own
        // TODO Move this logic to htmlanswers.
        if (metadata.okMessages) {
            metadata.okMessages.forEach((msg, idx) => {
                const textPayload = {
                    type: 'paragraph',
                    raw: msg.text,
                    text: msg.text,
                    tokens: [
                        {
                            type: 'text',
                            raw: msg.text,
                            text: msg.text
                        }
                    ]
                }

                data.answer.splice(0, 0, textPayload)
            })
        }

        // Token handler for every token returned from the marked lexer
        // Iterate over all tokens and reduce to a mutated token list used in renderer
        messageGroup.messages = data.answer.reduce((blockAccumulator, token, idx) => {
            // If the token has tokens, they aren't block but inline tokens
            // Iterate over inline tokens and add subtokens to inline token list
            if (Array.isArray(token.tokens)) {
                const inlineTokens = token.tokens.reduce((inlineAccumulator, inlineToken, idxx) => {
                    if (inlineToken.type === 'br') {
                        addToken(inlineAccumulator, id, idxx, { isInline: true, ...inlineToken })
                    }

                    if (inlineToken.text) {
                        const tags = '(%{Link\\(.*?\\)}|%{Image\\(.*?\\)}|%{Video\\(.*?\\)}|{(?:tile|card):?.*?}[\\s|\\S]*?{\\/(?:tile|card)})'
                        const tagsWithNonWords = new RegExp(tags, 'gim')
                        const tagsWithoutNonWords = new RegExp(`\\W*${tags}\\W*`, 'gim')
                        const removedTags = inlineToken.text.replaceAll(tagsWithoutNonWords, '')
                        const singleTags = removedTags.length <= 0
                        const splittedInlineContent = singleTags ? token.text.trim().split(tagsWithNonWords).filter(str => str.trim() !== '') : inlineToken.text.split(tagsWithNonWords)
                        const blockToken = { text: splittedInlineContent[0] }

                        if (splittedInlineContent.length > 1) {
                            const l = splittedInlineContent.length

                            // Iterate over splitted inline tokens
                            for (let x = 0; x < l; x++) {
                                // We need to set the type to text for inlineRendering
                                const tokenPayload = { isInline: true, text: splittedInlineContent[x], type: 'text' }

                                addToken(inlineAccumulator, id, idxx + x, tokenPayload)
                            }
                        } else {
                            addToken(singleTags ? blockAccumulator : inlineAccumulator, id, idxx, { isInline: !singleTags, ...(singleTags ? blockToken : inlineToken) })
                        }
                    }

                    return inlineAccumulator
                }, [])

                if (inlineTokens.length > 0) {
                    const textPayload = {
                        ...messagePayload(id, idx),
                        mediaType: 'paragraph',
                        inlineTokens
                    }

                    blockAccumulator.push(textPayload)
                }
            } else {
                addToken(blockAccumulator, id, idx, token)
            }

            return blockAccumulator
        }, [])

        return messageGroup
    }
}
