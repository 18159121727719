/* eslint-disable no-unused-vars */
import { h } from 'hyperapp'
import { Article } from './conversation'
import { Close } from './close'

export const Cta = () => (state, actions) => {
    const ctaConversation = state.conversation.filter(messageGroup => messageGroup.isCTAMessage)

    if (!state.isOpen && ctaConversation.length > 0 && state.chatLayout !== 'inline') {
        return <cxco-c-push class="cxco-c-push">
            <Close click={actions.flushCTAMessages}
            />
            {ctaConversation.map((messageGroup) => (<Article messageGroup={messageGroup} isCta={true} />))}
        </cxco-c-push>
    }
}
