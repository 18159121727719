
const debug = require('debug')('wcc:ui-chat')

/**
 * translatable/customizable content
 */
const defaultLayoutLabels = {
    title: '',
    subtitle: '',
    inputPlaceholder: 'type here',
    dialogChooseAgain: 'Choose Again',
    dialogNewChoice: 'New Topic',
    faqsTitle: '',
    resetModalTitle: 'Reset conversation',
    resetModalText: 'Are you sure you want to reset the conversation?',
    resetModalYesText: 'Yes',
    resetModalNoText: 'No'
}

/**
 * initialState: 'opened' | 'minimized' | 'closed' | 'previousValue'
 */
const DEFAULT_LAYOUT_MODES = {
    showInput: true,
    showAvatar: false,
    showFaqs: false,
    isInline: false,
    initialState: 'minimized',
    scrollType: 'question',
    chatLayout: 'floating',
    dialogOptionsLayout: 'horizontal',
    feedbackSubmittedMessage: 'Thanks for your feedback',
    feedbackPlaceholderMessage: 'Give your feedback',
    feedbackSubmitButtonText: 'Send'
}

/**
 * Default Feedback State values
 */
const DEFAULT_FEEDBACK_STATE = {
    showGlobal: false,
    showThumbs: false,
    showDialog: false
}

export const state = {
    // chatbox is the full window
    isOpen: true,
    isLoading: false,
    showInput: true,
    showAvatar: false,
    showFaqs: false,
    // input:
    text: '',
    rows: 1,
    conversation: [],
    faqs: [],
    supportedLanguages: undefined,
    filteredLanguages: undefined,
    selectedLanguage: undefined,
    selectedLanguageInput: '',
    detectedLanguage: undefined,
    queuedAnswer: undefined,
    showLanguagesList: false,
    translationEnabled: false,
    faqsDelay: 500,
    feedbackQueue: undefined,
    bubbleDelay: 1500,
    dialogOptionsDelay: 500,
    clearDialogOptionsDelay: 0,
    feedbackDialogPathData: undefined,
    inputState: [],
    dialogOptions: {
        options: [],
        isChangeable: undefined,
        dialogPath: undefined
    },
    pane: {
        // Options for pane modules can go here
    },
    isFirstChat: undefined,
    modal: {
        show: false,
        title: '',
        text: '',
        buttons: []
    },
    avatarUrl: '',
    agentName: ''
}

/**
 * Gets the properties defined in the config that should be applied to the state.
 * These override the storage settings.
 * @param {Object} config
 */
export const getStateSettings = (config) => {
    const cfgState = {}

    try {
        // add layout
        if (typeof config.layout === 'undefined') {
            config.layout = {}
        }

        // add feedback
        if (typeof config.feedback === 'undefined') {
            config.feedback = {}
        }
        cfgState.feedback = {}

        if (typeof config.layout.initialState === 'undefined') {
            config.layout.initialState = DEFAULT_LAYOUT_MODES.initialState
        }
        cfgState.initialState = config.layout.initialState

        switch (cfgState.initialState) {
        case 'closed':
            cfgState.isOpen = false
            cfgState.isHidden = true; break
        case 'minimized':
            cfgState.isOpen = false
            cfgState.isHidden = false; break
        case 'previousValue':
        case 'opened':
        default:
            cfgState.isOpen = true
            cfgState.isHidden = false; break
        }

        Object.keys(DEFAULT_LAYOUT_MODES).forEach((key) => {
            if (Object.prototype.hasOwnProperty.call(config.layout, key) && key !== 'initialState') {
                cfgState[key] = config.layout[key]
            }
        })

        // Copy these keys from config.layout with fallback on defaultConfig
        Object.keys(defaultLayoutLabels).forEach((key) => {
            if (Object.prototype.hasOwnProperty.call(config.layout, key)) {
                cfgState[key] = config.layout[key]

                return
            }

            if (Object.prototype.hasOwnProperty.call(defaultLayoutLabels, key)) {
                cfgState[key] = defaultLayoutLabels[key]
            }
        })

        Object.keys(DEFAULT_FEEDBACK_STATE).forEach((key) => {
            if (Object.prototype.hasOwnProperty.call(config.feedback, key)) {
                cfgState.feedback[key] = config.feedback[key]
            } else {
                // default values
                cfgState.feedback[key] = DEFAULT_FEEDBACK_STATE[key]
            }
        })

        cfgState.pane = config.pane || {}

        if (Object.prototype.hasOwnProperty.call(config, 'bubbleDelay')) {
            cfgState.bubbleDelay = config.bubbleDelay
        }

        if (Object.prototype.hasOwnProperty.call(config, 'inactiveEventTimer')) {
            cfgState.inactiveEventTimer = config.inactiveEventTimer
        }

        if (config.isIKB) {
            cfgState.isFirstChat = config.isFirstChat
            cfgState.showInput = !config.isFirstChat
            cfgState.chatLayout = 'inline'
        }

        cfgState.translationEnabled = !!config.translationEnabled
    } catch (error) {
        debug(error)
    }

    return cfgState
}

/**
 *
 * @param {Object} defaultState - the initial state
 * @param {Object} loadedState - state from browser storage
 * @param {*} configState - cxcoConfig object
 */
export const mergeStates = (defaultState, configState, loadedState) => ({ ...defaultState, ...configState, ...loadedState })
