/* eslint-disable no-unused-vars */
import { h } from 'hyperapp'

// Label needs to be the current answer
export const Feedback = ({ id, feedbackOptions }) => {
    const { score } = feedbackOptions

    return (
        <cxco-c-feedback class="cxco-c-feedback" key={`feedback-bubble-${id}`} data-state={typeof score !== 'undefined' ? 'open' : 'closed'}>
            <cxco-c-feedback__body class="cxco-c-feedback__body">
                <FeedbackBody isVisible={score < 0} id={id} feedbackOptions={feedbackOptions}></FeedbackBody>
                <FeedbackFooter isVisible={score < 0} id={id}></FeedbackFooter>
            </cxco-c-feedback__body >
        </cxco-c-feedback >
    )
}

export const FeedbackBody = ({ isVisible, id, feedbackOptions }) => (state, actions) => {
    if (isVisible) {
        return (
            <cxco-c-feedback__main class="cxco-c-feedback__main">
                <textarea
                    id={`chatFeedback_${id}`}
                    class="cxco-c-input  cxco-c-input--clean"
                    type="text"
                    value={feedbackOptions.comment}
                    rows={feedbackOptions.rows}
                    placeholder={state.feedbackPlaceholderMessage}
                    autocomplete="off"
                    oncreate={event => actions.onFeedbackCreate({ event, id })}
                    oninput={event => actions.onFeedbackInput({ event, id })}
                />
            </cxco-c-feedback__main>)
    }
}

export const FeedbackFooter = ({ isVisible, id }) => (state, actions) => {
    if (isVisible) {
        return (
            <cxco-c-feedback__footer class="cxco-c-feedback__footer">
                <SubmitButton text={state.feedbackSubmitButtonText} id={id} />
            </cxco-c-feedback__footer >)
    }
}

export const SubmitButton = ({ text, id }) => (state, actions) => {
    return (<button
        class="cxco-c-button"
        aria-label={text}
        onclick={e => {
            actions.submitInlineFeedback({ e, id })
            actions.toggleFeedbackMessage(id)
        }}>
        {text}
    </button>)
}
