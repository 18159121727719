import { app as App } from 'hyperapp'
import { actions as ActionsFactory } from './actions/'
import { state, mergeStates, getStateSettings } from './state/'
import { view } from './views/'
import { insertOrUpdateStyleTag } from './utils'

const window = require('global/window')
const debug = require('debug')('wcc:wcc-ui')
let defaultStyles = ''

try {
    defaultStyles = require('./scss/main.scss').default[0][1]
} catch (error) {
    console.error('defaultStyles not found')
}

export { default as htmlAnswers } from './plugins/htmlAnswers'

/**
 * Initialize main wcc-widget
 * @param {HTMLElement} elm
 * @returns {Object} with install method, called by core.use for plugins
 */
export default function (elm) {
    return {
        install (core) {
            const actions = ActionsFactory(core)
            let mergedState
            const defaultState = { wccId: core.wccId, ...state } // default application state
            const configState = getStateSettings(core.config) // from server
            const loadedState = core.storage.get() // from store

            try {
                mergedState = mergeStates(defaultState, configState, loadedState)
            } catch (e) {
                console.error('error loading from storage or config')
                mergedState = Object.assign(defaultState, configState)
            }

            if (!mergedState.supportedLanguages && mergedState.translationEnabled) {
                core.socket.emit('get_supported_languages')
            }

            insertOrUpdateStyleTag(elm)({ id: 'default', cssText: defaultStyles })

            const chatApp = new App(mergedState, actions.actions, view, elm)

            core.on('answer', chatApp.onAnswer)
            core.on('request', chatApp.onRequest)
            core.on('ui.render', chatApp.onRender)
            core.on('ui.archive', chatApp.archive)
            core.on('ui.languageDetected', chatApp.languageDetected)
            core.on('ui.changeState', chatApp.changeState)
            core.on('ui.setSelectedLanguage', chatApp.setSelectedLanguage)
            core.on('ui.reset', chatApp.resetConversation)
            core.on('ui.updateStyle', insertOrUpdateStyleTag(elm))

            debug('chatbot initialized with state: ', mergedState)

            window.addEventListener('beforeunload', function (e) {
                try {
                    core.broadcastChannel.close()
                    chatApp.processFeedback()
                } catch (error) {
                    debug('error processing events', error)
                }
            })
        }
    }
}
