/* eslint-disable no-unused-vars */
import { h } from 'hyperapp'

/**
 * Replaces the video placeholder by the actual video
 * @param {Object} data
 */

export const Video = ({ url, alignment, width, isHidden, isCTAMessage, type, isInline, msgActions }) => (
    <iframe
        src={url}
        class={ `cxco-c-image  ${alignment} ${type === 'question' || isCTAMessage ? 'cxco-u-animation--fade-in-right' : 'cxco-u-animation--fade-in-left'}  ${isInline || !isHidden ? '' : 'cxco-u-hidden'}`}
        style={`height:250px;width:${width}`}
        frameborder="0"
        allowfullscreen="allowfullscreen"
        mozallowfullscreen="mozallowfullscreen"
        msallowfullscreen="msallowfullscreen"
        oallowfullscreen="oallowfullscreen"
        webkitallowfullscreen="webkitallowfullscreen"
        onupdate = { msgActions.scrollToOnUpdate }
    ></iframe>
)
