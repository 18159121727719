/* eslint-disable no-unused-vars */
import { h } from 'hyperapp'
import { scrollElement, addSendLinkClickEventListener } from '../../utils'
import { renderer } from '../../renderer'
import { TextNode } from './textnode'

export const Bubble = ({ id, type, text, isHidden, isCTAMessage, inlineTokens, msgActions }) => (state, actions) => (<cxco-c-bubble
    data-bubble-id={`${id}`}
    key={`bubble-${id}`}
    class={`cxco-c-bubble  cxco-u-animation  ${type === 'question' || isCTAMessage ? 'cxco-c-bubble--inverted  cxco-u-animation--fade-in-right' : 'cxco-u-animation--fade-in-left'}  ${isHidden ? 'cxco-u-hidden' : ''}`}
    oncreate={(elm) => {
        if (elm) {
            addSendLinkClickEventListener(elm, msgActions.sendLinkClick)
            scrollElement(elm, 'question')
        }
    }}
    onupdate={ msgActions.scrollToOnUpdate }>

    { inlineTokens ? inlineTokens.reduce(renderer({ type, isCTAMessage, isInline: true, feedbackSubmittedMessage: state.feedbackSubmittedMessage })(msgActions), []) : <TextNode text={text} /> }
</cxco-c-bubble>)
