import { marked } from 'marked'

const renderer = {
    link (href, title, text) {
        return `<a target="_blank" rel="noopener noreferrer" href="${href}" title="${title || text}">${text}</a>`
    }
}

marked.setOptions({
    gfm: true,
    tables: true,
    breaks: true,
    sanitize: false,
    smartLists: true,
    headerPrefix: 'cxco'
})

// We use custom renderer for non CAIC markdown links
marked.use({ renderer })

export default {
    install (core) {
        core.before('answer', processAnswer)
    }
}

const createAnswer = (text) => {
    text = replaceVideos(text)
    text = marked(text)

    return createGenericAnswer(text)
}

const replaceVideos = (text) => {
    const videoPattern = /\[(\S*)\]\((https?:\/\/\S*(youtube|vimeo)\S*)\)/gim
    const matches = text.matchAll(videoPattern)

    for (const [, title, videoUrl] of matches) {
        const videoTemplate = `<iframe
        class="cxco-c-image"
        src="${videoUrl}"
        title="${title}"
        style="height:250px;width:500px"
        frameborder="0"
        allowfullscreen="allowfullscreen"
        mozallowfullscreen="mozallowfullscreen"
        msallowfullscreen="msallowfullscreen"
        oallowfullscreen="oallowfullscreen"
        webkitallowfullscreen="webkitallowfullscreen"
    ></iframe>`

        text = text.replace(videoPattern, videoTemplate)
    }

    return text
}

const createGenericAnswer = (text) => ({
    type: 'paragraph',
    raw: text,
    text,
    tokens: [
        {
            type: 'text',
            raw: text,
            text: text
        }
    ]
})

const generateSpace = {
    type: 'space',
    raw: '\n\n'
}

/**
 * Turn markdown answer to html answer
 *
 * Mutates the answerPayload by reference.
 * Removes the Dialog Options from the answer, as they're always after the text in a separate bubble.
 * @param {object} answerPayload
 */
function processAnswer (answerPayload) {
    if (answerPayload.cancel) return
    // botrouter check
    answerPayload.metadata.origin === 'botRouter' ? handleBotRouterPayload(answerPayload) : handleDcxPayload(answerPayload)
}

/**
 * Process the conversationMesssages from BotRouter
 * @param {object} answerPayload
 */
const handleBotRouterPayload = (answerPayload) => {
    let options

    answerPayload.data.answer = answerPayload.data.answer.reduce((accumulator, conversationMessage) => {
        conversationMessage.$type === 'listPicker'
            ? options = conversationMessage.buttons
            : accumulator.push(createAnswer(conversationMessage.text))
        accumulator.push(generateSpace)

        return accumulator
    }, [])

    if (options) {
        const dialogOptions = convertToDialogOptions(options)

        answerPayload.data.dialogOptions = dialogOptions
        answerPayload.data.type = 'dialog'
    }
}

// get options by their keys
const convertToDialogOptions = (options) => {
    return options.map(x => x.title)
}

const handleDcxPayload = (answerPayload) => {
    const lexer = new marked.Lexer()

    // Marked doesn't offer extensibility to add custom rules to the lexer
    // We can modify predefined tokenizer rules,
    // But we don't want to replace the lexer implementation to replace the lexer.prototype.blockTokens & inlineTokens
    // https://github.com/markedjs/marked/issues/1693
    // https://github.com/markedjs/marked/issues/1695

    lexer.tokenizer.rules.block.html = /^ {0,3}(?:<(script|pre|style)[\s>][\s\S]*?(?:<\/\1>[^\n]*\n+|$)|<!--(?!-?>)[\s\S]*?-->[^\n]*(\n+|$)|<\?[\s\S]*?\?>\n*|<![A-Z][\s\S]*?>\n*|<!\[CDATA\[[\s\S]*?\]\]>\n*|<\/?(address|article|aside|base|basefont|blockquote|body|caption|center|col|colgroup|dd|details|dialog|dir|div|dl|dt|fieldset|figcaption|figure|footer|form|frame|frameset|h[1-6]|head|header|hr|html|iframe|legend|li|link|main|menu|menuitem|meta|nav|noframes|ol|optgroup|option|p|param|section|source|summary|table|tbody|td|tfoot|th|thead|title|tr|track|ul|img)(?: +|\n|\/?>)[\s\S]*?(?:\n{2,}|$)|<(?!script|pre|style)([a-z][\w-]*)(?: +[a-zA-Z:_][\w.:-]*(?: *= *"[^"\n]*"| *= *'[^'\n]*'| *= *[^\s"'=<>`]+)?)*? *\/?>(?=[ \t]*(?:\n|$))[\s\S]*?(?:\n{2,}|$)|<\/(?!script|pre|style)[a-z][\w-]*\s*>(?=[ \t]*(?:\n|$))[\s\S]*?(?:\n{2,}|$))/i
    answerPayload.data.mediaBlocks = []
    if (answerPayload.data && answerPayload.data.answer) {
        answerPayload.data.answer = answerPayload.data.answer.replace(/\* %{DialogOption(.*)}(\r\n|\r|\n)?/gmi, '')
        answerPayload.data.answer = unescapeMarkdown(answerPayload.data.answer)

        replaceCustomElements(answerPayload.data, answerPayload.metadata)
        replaceHyperlinks(answerPayload.data, answerPayload.metadata)

        answerPayload.data.answer = lexer.lex(answerPayload.data.answer)
        // Because of the missing extensibility we replace tags in our custom lexer
    }
}

/**
 * Unescapes markdown
 * @param {String} answer
 */
function unescapeMarkdown (answer) {
    return answer.replace(/^\\#/gm, '#').replace(/^\\>/gm, '>')
}

/**
 * Replaces Custom HTML Elements
 * @param {Object} data
 * @param {Object} metadata

 */
function replaceCustomElements (data, metadata) {
    replaceMediaBlocks(data, metadata)
}

/**
 * Replaced custom {card|tile} tags with HTML tags.
 * @param {Object} data
 * @param {Object} metadata
 */
function replaceMediaBlocks (data, metadata) {
    const { links, images, dialogOptions } = data
    const pattern = /{(tile|card):?(.*?)}[\s|\S]*?{\/(tile|card)}/gim
    const matches = data.answer.match(pattern)

    if (
        Array.isArray(images)
        && images.length > 0
        && matches !== null
    ) {
        for (const [i, mediaBlock] of matches.entries()) {
            const image = images[i]

            if (image && image.linkId) {
                const match = /{(tile|card):(.*?)}[^{/]*%{Image\(.\)}([^{/]*){\/(tile|card)}/
                const properties = /=(.*?)(}|;)/
                const title = mediaBlock.match(properties)[1]
                let option

                if (dialogOptions && dialogOptions.find(opt => opt === title) && metadata.dialogPath) {
                    option = {
                        question: dialogOptions.find(opt => opt === title),
                        dialogPath: metadata.dialogPath
                    }
                }

                const payload = {
                    mediaType: mediaBlock.match(match)[1],
                    link: links.find((link) => link.id === image.linkId),
                    option,
                    image: image.name,
                    title,
                    description: mediaBlock.match(match)[3],
                    goldenRatioThreshold: image.additionalData.originalImageHeight * 1.618 > image.additionalData.originalImageWidth
                }

                data.mediaBlocks.push(payload)
                // Link can also be dialog options (action)
                data.answer = data.answer.replace(
                    mediaBlock,
                    `%{MediaBlock(${i})}`
                )
            }
        }
    }
}

/**
 * Replaces the hyperlinks in the correct markdown format
 * @param {Object} data
 * @param {Object} metadata
 */
function replaceHyperlinks (data, metadata) {
    let answer = data.answer

    if (Array.isArray(data.links) && data.links.length > 0) {
        data.links.forEach((h, index) => {
            if (!(data.ogs && index === 0)) {
                const pattern = new RegExp('%\\{Link\\(' + h.id + '\\)\\}', 'gi')
                const a = `[${h.text}](${h.url})`

                answer = answer.replace(pattern, a)
            }
        })
    }
    data.answer = answer
}
