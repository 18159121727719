/* eslint-disable no-unused-vars */
import { h } from 'hyperapp'
import { ResetIcon } from './icons'
/**
 * Generates the reset button
 * @param {Object} payload Containing the click event listener callback
 * @returns {Object} Reset component
 */
export const Reset = ({ click }) => (state, actions) => {
    return (<button
        class={'cxco-c-icon'}
        aria-label="reset"
        onclick={click}>
        <ResetIcon />
    </button>)
}
