/* eslint-disable no-unused-vars */
import { h } from 'hyperapp'
import { CloseIcon } from './icons'

export const Close = ({ click }) => (state, actions) => {
    if (state.chatLayout !== 'inline') {
        return (<button
            class="cxco-c-icon"
            aria-label="Close"
            onclick={click}
        >
            <CloseIcon />
        </button>)
    }
}
