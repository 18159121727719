/**
 * Controls what is exposed to the window object
 * and provides an API to be used externally.
 */
import window from 'global/window'
const debug = require('debug')('wcc:cxco')
const SOCKET_MESSAGE_TYPES = require('./socket').MESSAGE_TYPES
const { sendSetSelectedLanguage, sendSetContext } = require('./utils')

export default class WccWindowApi {
    constructor (core) {
        this.core = core
        this.eventQueue = (Array.isArray(window?.wcc?.[core.wccId])) ? window.wcc[core.wccId] : []

        if (core.wccId) {
            if (!window.wcc || typeof window.wcc !== 'object') {
                window.wcc = {}
            }
            window.wcc[core.wccId] = {
                push: this.push.bind(this),
                setSelectedLanguage: this.setSelectedLanguage.bind(this),
                getSelectedLanguage: this.getSelectedLanguage.bind(this),
                setContext: this.setContext.bind(this),
                ask: this.ask.bind(this)
            }
        } else {
            // For older versions we of this API we need to support a global push
            window.wcc = {
                push: this.push.bind(this)
            }
        }

        this.processQueue()
    }

    /**
     * Adds an event to the event queue and triggers processQueue.
     * @param {[]} event
     */
    push (event) {
        this.eventQueue.push(event)
        this.processQueue()
    }

    /**
     * Processes events in the event queue
     */
    processQueue () {
        while (this.eventQueue.length) {
            const event = this.eventQueue.shift()

            this.processAsyncEvent(event)
        }
    }

    /**
     * Triggers core method
     * @param {Object[]} event
     */
    processAsyncEvent (args = []) {
        debug(`processed event ${args[0]} ${args[1]}`)

        const method = args.shift()

        if (method === 'on') {
            const action = args.shift().split(':')
            const fn = args.shift()

            if (action[0] === 'before') {
                this.core.before(action[1], fn)
            } else if (action[0] === 'after') {
                this.core.after(action[1], fn)
            } else {
                // other on methods such as render.
                this.core[method](action[0], fn)
            }
        } else {
            switch (method) {
            case 'changeState':
            case 'sendEvent':
            case 'ask':
            case 'answer':
            case 'init':
            case 'archive':
            case 'updateConfig':
                try {
                    debug(`calling ${method} `)

                    this.core[method](...args)
                } catch (error) {
                    debug(`function ${method} not available`, error)
                    console.error(`function ${method} not available`)
                }
                break
            default: console.error(`couldn't find function ${method}`)
            }
        }
    }

    /**
     * Sets the given language code into the state for auto translation
     * @param {String} languageCode
     * @returns {Boolean} true if it's set and false if it isn't
     */
    async setSelectedLanguage (languageCode) {
        const languageSet = await sendSetSelectedLanguage(this.core.socket, languageCode)

        if (languageSet) {
            this.core.emit('ui.setSelectedLanguage', { selectedLanguage: languageCode, shouldNotify: false })
        }

        return languageSet
    }

    /**
     * Gets the selected language code
     * @returns {String} the set language code
     */
    async getSelectedLanguage () {
        return await new Promise(resolve => {
            this.core.socket.emit(SOCKET_MESSAGE_TYPES.GET_CURRENT_LANGUAGE, (response) => {
                resolve(response)
            })
        })
    }

    /**
     * Sets the context
     * @param {String} context
     * @param {String} value
     * @returns {Boolean} true if it's set and false if it isn't
     */
    async setContext (contextName, contextValue) {
        return await sendSetContext(this.core.socket, contextName, contextValue)
    }

    /**
     * Ask
     * @param {String|Object} userInput
     * @param {Boolean} hideInUI whether the request should show in the UI
     */
    ask (askPayload, hideInUI) {
        this.core.ask(askPayload, hideInUI)
    }
}
