/**
 * Modifies the config for IKB
 * @param {HTMLElement} parentElement the parent element to mount on
 * @param {Map} cores core instances registry
 */

export default function (parentElement, cores) {
    return {
        /**
         * Install plugin fn from core
         * @param {Object} config from core
         * @param {String} wccId from core
         */
        install ({ config, wccId }) {
            if (!parentElement.hasAttribute('data-wcc-ikb')) return

            config.isIKB = true
            config.isFirstChat = cores.keys().next().value === wccId

            if ((config.style || config.style === '') && typeof config.style === 'string') {
                const radiusPosition = config.isFirstChat ? 'right' : 'left'

                config.style = config.style.concat(` cxco-root .cxco-o-inline {--cxco-width: 100% ; --cxco-min-width: 100% ; --cxco-max-width: 100% ; --cxco-height: 100% ; --cxco-min-height: 100% ;--cxco-max-height: 100% ; border-top-${radiusPosition}-radius: 0; border-bottom-${radiusPosition}-radius: 0;}`)
            }
        }
    }
}
