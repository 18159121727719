export default {
    install (core) {
        core.before('answer', languageDetection(core))
    }
}

/**
 * Detects the new language and cancels the answer processing
 * @param {Object} core
 * @param {Object} answerPayload
 * @returns {Object} answerPayload with cancel property
 */
const languageDetection = core => answerPayload => {
    if (answerPayload?.metadata.translation?.newLanguageDetected) {
        // Clone the answer to lose reference
        // use spread to lose reference on root level in order to emit answerpayload without new cancel property but to keep all deep preferences
        core.emit('ui.languageDetected', { ...answerPayload })
        answerPayload.cancel = true
    }
}
