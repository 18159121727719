
/* eslint-disable no-unused-vars */
import { h } from 'hyperapp'

/**
 * Media block can contain link Preview, cards or tiles
 * @param {Object} data
 * @param {Object} metadata
 */
export const MediaBlock = ({ type, isCTAMessage, mediaType, link, option, image, title, description, goldenRatioThreshold, isHidden, isInline, msgActions }) => {
    if (option) {
        return (
            <article
                class={`cxco-c-${mediaType}  ${type === 'question' || isCTAMessage ? 'cxco-u-animation--fade-in-right' : 'cxco-u-animation--fade-in-left'}  ${isInline || !isHidden ? '' : 'cxco-u-hidden'}`}
                rel="noopener"
                target={`${link.target}`}
                data-id={`${link.id}`}
                data-dcx-link
                onclick={() => {
                    msgActions.ask(option)
                }
                }
            >
                {image ? <MediaBlockFigure mediaType={mediaType} image={image} goldenRatioThreshold={goldenRatioThreshold} /> : ''}
                {title && description ? <MediaBlockBody mediaType={mediaType} title={title} description={description} /> : ''}
            </article>
        )
    }

    return (
        <a
            class={`cxco-c-${mediaType}  ${type === 'question' || isCTAMessage ? 'cxco-u-animation--fade-in-right' : 'cxco-u-animation--fade-in-left'}  ${isInline || !isHidden ? '' : 'cxco-u-hidden'}`}
            href={`${link.url}`}
            rel="noopener"
            target={`${link.target}`}
            data-id={`${link.id}`}
            data-dcx-link
        >
            {image ? <MediaBlockFigure mediaType={mediaType} image={image} goldenRatioThreshold={goldenRatioThreshold} /> : ''}
            {title && description ? <MediaBlockBody mediaType={mediaType} title={title} description={description} /> : ''}
        </a>
    )
}

export const MediaBlockFigure = ({ mediaType, image, goldenRatioThreshold }) => {
    // Contain images with golden ratio proportions, CSS background-sizing animations should adhere to this
    return (
        <figure
            class={`cxco-c-${mediaType}__figure  ${
                goldenRatioThreshold ? `cxco-c-${mediaType}__figure--vertical` : ''
            }`}
            style={`background-image:url(${image});`}
        ></figure>
    )
}

export const MediaBlockBody = ({ mediaType, title, description }) => {
    return (<section class={`cxco-c-${mediaType}__main`}>
        <h3 class={`cxco-c-${mediaType}__title`}>{title}</h3>
        <p class={`cxco-c-${mediaType}__body`}>{description}</p>
    </section>)
}
