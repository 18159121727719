import { delay, getLastMessageGroupId, shouldTriggerReturnEvent, getMessagesWithProperty, navigateTo, hasVirtualKeyboard, toggleScrollLock, getDialogPathData, newDialogTriggeredInDialog } from '../utils'
import { createAnswerMessageGroup, createQuestionMessageGroup } from '../messageGroup'
import ElementQueries from '../../lib/element-queries/ElementQueries'

const SOCKET_MESSAGE_TYPES = require('../../../wcc-core/src/socket').MESSAGE_TYPES
const debug = require('debug')('wcc:actions')
const xssFilters = require('xss-filters')

export function actions (core) {
    /**
     * Checks whether it's a t-dialog.
     * @param {Object} answer
     * @param {Object} answer.data
     */
    const isTDialogAnswer = ({ data }) => {
        return data.type === 'tdialog'
    }

    /**
     * Checks whether it's a t-dialog end.
     * @param {Object} answer
     * @param {Object} answer.metadata
     */
    const isTDialogEnd = ({ metadata }) => {
        if (metadata && Object.prototype.hasOwnProperty.call(metadata, 'isDialogEnd')) {
            return metadata.isDialogEnd
        }

        return false
    }

    const changeMessageState = ({ id, state }, callback) => state.conversation.map(messageGroup => {
        if (messageGroup.id === id) {
            callback(messageGroup)
        }

        return messageGroup
    })

    /**
     * Set the input rows for auto expanding lines
     * @param {HTMLElement} event target (input)
     * @param {Number} initial input height (integer)
     */
    const setInputRows = (elm, initialHeight) => {
        const style = window.getComputedStyle(elm)
        const lineHeight = parseFloat(style.getPropertyValue('line-height')) || 16

        const minRows = 1 // Minimal amount of rows
        let rows = minRows

        // Direct reset rows to minimalise scrollHeight when input contracts
        elm.rows = minRows
        // Calculate rows by scroll height - initial height devided over CSS line height
        rows = Math.floor((elm.scrollHeight - initialHeight) / lineHeight)
        // Direct set rows to minimalise scrollHeight when input contracts
        elm.rows = minRows + rows

        // Return to state to ensure hydration doesn't fail
        return minRows + rows
    }

    /**
     * Iterates a list of items toggling their visibility adding delay in between.
     * @param {Object[]} items - items
     * @param {function} showItemAction - the function that handles items visibility.
     * @param {function} loadingAction - the function that shows a loader.
     * @returns {Promise} promise
     */
    const renderDelayer = (items, showItemAction, loadingAction, bubbleDelay) => {
        // show bubbles one by one
        const promiseConstructor = message => () =>
            Promise.resolve(message).then(showItemAction(message))

        // Activate loading before all promises are resolved
        if (loadingAction) {
            loadingAction(true)
        }

        // Create a promise array and chain them in a single cascade using reduce.
        return items.map(msg => promiseConstructor(msg))
            .reduce((previousPromise, nexPromise) => {
                return previousPromise
                    .then(delay(bubbleDelay))
                    .then(nexPromise)
            }, Promise.resolve()).catch(err => debug('err', err))
    }

    /**
     * Check if an `answer` originated from a given request `type`
     * @param {Object} metadata
     * @param {string} type - the type to check
     */
    const isOriginalRequestFromType = (metadata, type) => {
        return metadata.originalRequest && metadata.originalRequest.type && metadata.originalRequest.type === type
    }

    /**
     * Checks current url with regex pattern to retrieve question.
     * If `urlQuestionSpaceChar` is defined it replaces it with a space.
     * @param {string} urlQuestion
     */
    const getURLParameters = (urlQuestion, urlQuestionSpaceChar) => {
        const exp = new RegExp(`${urlQuestion}=(.*)`, 'i')
        let q

        // TODO search in URL with regex
        try {
            q = location.href.match(exp)[1]

            if (typeof urlQuestionSpaceChar !== 'undefined') {
                return decodeURI(q).replace(new RegExp(`\\${urlQuestionSpaceChar}`, 'gmi'), ' ')
            }

            return decodeURI(q)
        } catch {
            return undefined
        }
    }

    const actions = {
        /**
         * Toggles between open and minimized states
         */
        toggle: (e) => (state, actions) => {
            const chatShadowRoot = e.target?.getRootNode()
            const chatInput = chatShadowRoot.querySelector(`#cxcoChatInput${state.wccId}`)

            if (chatInput && !state.isOpen && !hasVirtualKeyboard()) {
                chatInput.focus()
            }

            toggleScrollLock(state.isOpen, chatShadowRoot.querySelector('.cxco-o-chat'))

            actions.flushCTAMessages()

            return ({
                isOpen: !state.isOpen
            })
        },
        flushCTAMessages: () => (state, actions) => {
            const conversation = state.conversation.map((messageGroup) => {
                messageGroup.isCTAMessage = false

                return messageGroup
            })

            return ({ conversation })
        },
        setLoading: (value = true) => state => {
            return typeof value === 'boolean' ? { isLoading: value } : { isLoading: false }
        },
        onCreate: target => state => ({ initialHeight: target.scrollHeight }),
        onInput: event => (state, actions) => {
            return ({ text: event.target.value, rows: setInputRows(event.target, state.initialHeight) })
        },
        onFocus: event => state => ({ inputState: [...state.inputState, 'focus'] }),
        onBlur: event => state => ({
            inputState: state.inputState.filter(st => st !== 'focus')
        }),
        onKeyUp: event => (state, actions) => {
            // onEnter..
            if (event.keyCode === 13) {
                actions.ask({ question: state.text.trim(), dialogPath: state.dialogOptions?.path })

                return ({ text: '', rows: 1 })
            }
        },
        onKeyDown: event => (state, actions) => {
            if (event.keyCode === 13) {
                event.preventDefault()
            }
        },
        onFeedbackCreate: ({ event, id }) => state => {
            event.focus()

            const conversation = changeMessageState({ id, state }, (message) => {
                message.feedbackOptions.initialHeight = event.scrollHeight
            })

            return { conversation }
        },
        onFeedbackInput: ({ event, id }) => state => {
            const conversation = changeMessageState({ id, state }, (message) => {
                const rows = setInputRows(event.target, message.feedbackOptions.initialHeight)

                message.feedbackOptions.comment = event.target.value
                message.feedbackOptions.rows = rows
            })

            return { conversation }
        },
        /**
         * Adds a new Question to the message list.
         * @param {Object} obj
         * @param {string} obj.question
         * @param {boolean} obj.isNewTopic
         * @param {string?} obj.newTopic
         * @param {Object} obj.dialogOptions
         * @param {boolean} obj.setDividerBefore
         */
        add: ({ question, isNewTopic, newTopic, dialogOptions, setDividerBefore }) => (state, actions) => {
            if (typeof question === 'string') {
                if (typeof isNewTopic === 'boolean'
                    && isNewTopic
                    && typeof newTopic === 'undefined') {
                    newTopic = state.dialogNewChoice
                }
                const lastMessageId = getLastMessageGroupId(state.conversation)
                const messageGroup = createQuestionMessageGroup(lastMessageId)(question, { isNewTopic, newTopic, dialogOptions, setDividerBefore })

                actions.appendMessageGroup(messageGroup)
            } else {
                console.error('Couldn\'t add question.')
            }
        },
        /**
         * Adds a messageGroup to the conversation
         * @param {Object|Object[]} messageGroup
         */
        appendMessageGroup: messageGroup => state => ({ isLoading: false, conversation: state.conversation.concat(messageGroup) }),
        showMessage: message => state => {
            const conversation = state.conversation.map(messageGroup => {
                messageGroup.messages.map(msg => {
                    if (msg.id === message.id) {
                        msg.isHidden = false
                    }

                    return msg
                })

                return messageGroup
            }
            )

            return ({ conversation })
        },
        /**
         * `Ask` action goes through DCX.
         */
        ask: ({ question, dialogPath, isNewTopic, dialogOptions }) => (state, actions) => {
            if (question.replace(/\s/gm, '') === '') {
                return ({ text: '', rows: 1 })
            }

            const askPayload = {
                data: {
                    userInput: question
                },
                metadata: {
                    dialogPath,
                    isNewTopic,
                    dialogOptions,
                    inputLanguage: state?.selectedLanguage?.languageCode
                }
            }

            core.ask(askPayload)

            return ({ text: '', rows: 1 })
        },
        /**
         * Activates a link click
         * @param {HTMLElement} element
         */
        sendLinkClick: (element) => (state, actions) => {
            const anchorElement = element.currentTarget
            const linkUrl = anchorElement.href
            const linkId = anchorElement.getAttribute('data-id')
            const interactionId = anchorElement.getAttribute('data-interactionid')

            if (!linkUrl || !linkId || !interactionId) {
                debug('invalid sendLinkClick payload')

                return ({})
            }

            core.sendLinkClick({
                data: { interactionId, linkUrl, linkId }
            })

            return ({})
        },
        onRequest: ({ data, metadata }) => (state, actions) => {
            if (data?.userInput && !metadata?.hideInUI) {
                actions.add({ question: data.userInput, ...metadata })
            }
        },
        onAnswer: (answer) => (state, actions) => {
            try {
                // language detected stop any processing before language translation is enabled or disabled
                if (answer.cancel) return ({})

                const { data, metadata } = answer

                // If (new) live agent takes over conversation, set new avatarUrl
                if (metadata.agent?.avatarUrl && state.avatarUrl !== metadata.agent?.avatarUrl) {
                    actions.setAvatarUrl(metadata.agent.avatarUrl)
                }
                // If (new) live agent takes over conversation, set new agentName
                if (metadata.agent?.name && state.agentName !== metadata.agent?.name) {
                    actions.setAgentName(metadata.agent.name)
                }

                // Clear global dialog options by default, except for dialog step
                if (metadata?.originalRequest?.type !== 'dialogstep' && state.dialogOptions?.options?.length > 0) {
                    actions.clearDialogOptions()
                }

                // Renderdelayer needs metadata Object, but metadata does not need to have keys
                if (data?.constructor === Object && Object.keys(data).length !== 0 && metadata?.constructor === Object) {
                    const now = Date.now()

                    const pagePushAddition = data?.outputAdditions?.pagepush

                    if (pagePushAddition) {
                        navigateTo(pagePushAddition)
                    }

                    actions.handleFeedback(answer)

                    actions.setLastInteraction(now)

                    // ignore these payload types because it's already stored.
                    if (isOriginalRequestFromType(metadata, 'dialogstep')
                        || isOriginalRequestFromType(metadata, 'feedback')
                        || isOriginalRequestFromType(metadata, 'linkclick')
                    ) {
                        return ({})
                    }

                    if (typeof metadata.isNewTopic === 'boolean'
                        && metadata.isNewTopic
                        && typeof metadata.newTopic === 'undefined') {
                        answer.metadata.newTopic = state.dialogNewChoice
                    }

                    const lastMessageId = getLastMessageGroupId(state.conversation)
                    const messageGroup = createAnswerMessageGroup(lastMessageId)(answer)

                    // add all bubbles to storage.
                    actions.appendMessageGroup(messageGroup)

                    // set loading function if needed (for selected language we do not what loading bubbles)
                    const loadingCallback = metadata.disableLoader ? false : actions.setLoading

                    renderDelayer(messageGroup.messages, actions.showMessage, loadingCallback, state.bubbleDelay)
                        .then(() => actions.setLoading(false))
                        .then(delay(state.bubbleDelay))
                        .then(() => {
                            actions.showContextualFaqs(answer)
                            actions.setInputType(answer)
                            if (messageGroup.dialogOptions && Object.keys(messageGroup.dialogOptions).length > 0) {
                                actions.showDialogOptions(messageGroup.id)
                            }
                        })

                    actions.handleTDialogPayload(answer)
                }
            } catch (error) {
                debug(error)
            }

            return ({})
        },
        onRender: (event) => (state, actions) => {
            core.emitCustomEvent('render', { event, state })

            return ({})
        },
        startFeedback: ({ metadata }) => (state, actions) => {
            core.sendEvent('show_feedback')

            return ({ feedbackInteractionId: metadata.interactionId })
        },
        setMessageFeedbackValues: ({ id, score, label, comment }) => (state) => {
            // After submitting we reset this node to default
            const conversation = changeMessageState({ id, state }, messageGroup => {
                if (typeof score === 'number') {
                    messageGroup.feedbackOptions.score = score
                }

                if (typeof label === 'string') {
                    messageGroup.feedbackOptions.label = xssFilters.inHTMLData(label)
                }

                if (typeof comment === 'string') {
                    messageGroup.feedbackOptions.comment = xssFilters.inHTMLData(comment)
                }

                messageGroup.feedbackOptions.initialHeight = undefined
                messageGroup.feedbackOptions.rows = 1
            })

            return ({ conversation })
        },
        /**
         * Shows/Hides 'thanks for your feedback message'.
         */
        toggleFeedbackMessage: id => state => {
            const conversation = changeMessageState({ id, state }, (messageGroup) => {
                messageGroup.feedbackOptions.showFeedbackMessage = !messageGroup.feedbackOptions.showFeedbackMessage
            })

            return ({ conversation })
        },
        submitInlineFeedback: ({ e, id }) => (state, actions) => {
            const currentMessageGroup = state.conversation.find(messageGroup => messageGroup.id === id)

            if (!currentMessageGroup) {
                return ({})
            }

            const feedbackPayload = {
                data: {
                    score: currentMessageGroup.feedbackOptions.score || 0,
                    interactionId: currentMessageGroup.interactionId,
                    comment: currentMessageGroup.feedbackOptions.comment,
                    label: currentMessageGroup.feedbackOptions.label
                }
            }

            core.sendFeedback(feedbackPayload)
            core.emitCustomEvent('openFeedback.send', e)

            // After submitting we reset this node to default
            return ({
                conversation: changeMessageState({ id, state }, messageGroup => {
                    messageGroup.feedbackOptions.score = undefined
                    messageGroup.feedbackOptions.comment = ''
                    messageGroup.feedbackOptions.initialHeight = undefined
                    messageGroup.feedbackOptions.rows = 1
                })
            })
        },
        showInput: value => (state) => ({ showInput: value }),
        /**
         * When the Bot initializes it performs this action
         */
        initVA: () => (state, actions) => {
            const question = getURLParameters(core.config.urlQuestion, core.config.urlQuestionSpaceChar)

            if (question) {
                const askPayload = {
                    data: {
                        userInput: question
                    },
                    metadata: { isNewTopic: false }
                }

                core.ask(askPayload)

                if (!state.isOpen) {
                    return ({
                        isOpen: true
                    })
                }
            } else if (state.conversation.length > 0) {
                // process the hidden bubbles.
                const messages = getMessagesWithProperty(state.conversation, 'isHidden', true)

                if (messages) {
                    renderDelayer(messages, actions.showMessage, actions.setLoading, state.bubbleDelay)
                        .then(() => {
                            actions.setLoading(false)
                        })
                }
            }
        },
        storeStateInStorage: () => (state) => {
            core.storage.store(state)
        },
        /**
         * Handles a link click.
         * Sends a link click event to the API
         */
        handleClick: ({ interactionId, linkUrl, linkId }) => (state, actions) => {
            core.sendLinkClick({
                data: { interactionId, linkUrl, linkId }
            })
        },
        botReady: (e) => () => {
            core.emitCustomEvent('chatbot.ready')
        },
        changeState: (payload) => (state, actions) => {
            if (typeof payload !== 'object') {
                debug('invalid state payload')

                return {}
            }

            return { ...payload }
        },
        /**
         * When the answer is a T-Dialog the state needs to update inTDialog.
         * Side-effects: showinput, sendEvent(returnEvent) .
         * @param {Object} answer - response payload
         */
        handleTDialogPayload: (answer) => (state, actions) => {
            if (isTDialogAnswer(answer)) {
                // not the end slot
                if (!isTDialogEnd(answer)) {
                    // left ikb chat input should always stay hidden
                    if (!state.isFirstChat) {
                        actions.showInput(true)
                    }

                    return ({ inTDialog: true })
                } else {
                    // trigger return event if defined
                    if (shouldTriggerReturnEvent(answer)) {
                        setTimeout(() => { core.sendEvent(answer.metadata.returnEvent) }, state.bubbleDelay)
                    }

                    return ({ inTDialog: false })
                }
            }
        },
        /**
         * Change the input type based on an output addition
         * @param {Object} answer
         * @param {Object} answer.data
         */
        setInputType: ({ data }) => (state, actions) => {
            const inputType = (data.outputAdditions && data.outputAdditions.inputType) || 'text'

            return ({ inputType })
        },
        /**
         * Updates last interaction date.
         * @param {Date} date
         */
        setLastInteraction: date => (state, actions) => ({ lastInteraction: date }),
        /**
         * Sets the feedback dialog path data
         * @param {Object} data
         */
        setFeedbackDialogPathData: data => () => ({ feedbackDialogPathData: data }),
        /**
         * Clears the feedback dialog path data
         */
        clearFeedbackDialogPathData: () => () => ({ feedbackDialogPathData: undefined }),
        /**
         * Handles feedback flow
         * @param {Object} answer
         * @returns
         */
        handleFeedback: ({ data, metadata }) => (state, actions) => {
            const { dialogPath, isDialogEnd } = metadata

            // If the user is out of the feedback dialog by asking a question and the question triggers another dialog (not a dialog feedback dialog)
            // feedback has ended, send feedback and clear all feedback data
            if (!dialogPath) {
                actions.processPendingFeedback()

                return
            }

            // If we are inside a pending feedback dialog flow, or when its triggered by the event
            if ((isOriginalRequestFromType(metadata, 'event') && metadata.originalRequest.data.eventName === 'show_feedback') || state.feedbackDialogPathData) {
                const feedbackScore = data?.outputAdditions?.feedbackScore
                const currentDialogPathData = getDialogPathData(dialogPath)

                actions.setFeedbackDialogPathData(getDialogPathData(dialogPath))

                // If we are inside feedback dialog and got the feedback addition add feedback to queue
                if (feedbackScore) {
                    const score = parseInt(feedbackScore)

                    actions.addPendingFeedbackItem({
                        score,
                        label: score <= 0 ? 'no' : 'yes',
                        interactionId: state.feedbackInteractionId,
                        comment: metadata.originalRequest.data.userInput
                    })
                }

                // Send feedback if current answer triggers a new dialog during a feedback dialog
                // Or if the feedback dialog has ended
                if (newDialogTriggeredInDialog(state.feedbackDialogPathData, currentDialogPathData) || isDialogEnd) {
                    actions.processPendingFeedback()
                }

                // Reset feedbackDialogPathData to state that feedback dialog has finished
                if (isDialogEnd) {
                    actions.clearFeedbackDialogPathData()
                }
            }
        },
        /**
         * Sends all the pending feedback entries.
         */
        processPendingFeedback: () => (state, actions) => {
            if (state.feedbackQueue) {
                core.sendFeedback({
                    data: state.feedbackQueue
                })
                actions.deleteFeedbackQueue()
            }
        },
        /**
         * Delete feedback entry by ID if given, else reset queue.
         * @param {string} interactionId
         */
        deleteFeedbackQueue: () => (state) => {
            state.feedbackQueue = undefined

            return ({ feedbackQueue: undefined })
        },
        addPendingFeedbackItem: (feedback) => () => ({ feedbackQueue: feedback }),
        stepOverTDialog: () => (state, actions) => {
            if (state.dialogOptions.options.length > 0) {
                actions.clearDialogOptions()
            }
            core.stepOver()

            return ({
                inTDialog: false
            })
        },
        /**
         * Selects the dialogoption based on messageGroup id and option id.
         * Then adds the message and send the selected option with ask.
         * @param {Object} askPayload
         * @param {number} messageGroupId
         * @param {number} id
         */
        selectDialogOption: ({ askPayload, messageGroupId, id }) => (state, actions) => {
            const activeMessageGroup = state.conversation.find(messageGroup => messageGroup.id === messageGroupId)

            if (activeMessageGroup) {
                const visibleDialogOptions = state.dialogOptions.options.filter(option => !option.isHidden).reverse()

                if (visibleDialogOptions && visibleDialogOptions.length > 0) {
                    renderDelayer(visibleDialogOptions, actions.toggleDialogOption, null, state.clearDialogOptionsDelay).then(() => {
                        actions.clearMessageGroupDialogOptions({ messageGroupId, id })
                        actions.clearDialogOptions()
                    }).then(() => {
                        // here we add the question to the conversation with the data about options
                        const { dialogOptions } = state.conversation.find(messageGroup => messageGroup.id === messageGroupId)

                        actions.ask({ ...askPayload, dialogOptions })
                    })
                }
            }
        },
        /**
         * This action is triggered when the user has already selected a dialog option from a dialog
         * and wants to show all unselected dialog options
         *
         * @param {number} id messageGroup id
         * @param {string} path dialogpath or tdialogpath.
         */
        resetDialogOptions: ({ id, path }) => (state, actions) => {
            actions.clearDialogOptions()

            // dialog step is needed so the 'ask' request is inside the dialog session.
            core.dialogStep(path)
            actions.showDialogOptions(id)
        },
        /**
         * Shows specific dialogOption based on id
         * @param {Object} option dialog option or quick reply
         */
        toggleDialogOption: currentOption => state => {
            const dialogOptions = state.dialogOptions

            dialogOptions.options.map(option => {
                if (option.id === currentOption.id) {
                    option.isHidden = !option.isHidden
                }

                return option
            })

            return ({ dialogOptions })
        },
        /**
         * Shows all the hidden dialog options one by one
         * @param {number} id
         */
        showDialogOptions: id => (state, actions) => {
            const activeMessageGroup = state.conversation.find(messageGroup => messageGroup.id === id)

            if (activeMessageGroup) {
                const { dialogOptions } = activeMessageGroup
                const hiddenDialogOptions = dialogOptions?.options?.filter(option => option.isHidden)

                if (hiddenDialogOptions && hiddenDialogOptions.length > 0) {
                    actions.setMessageGroupDialogOptions(id)
                    actions.setDialogOptions(id)
                    renderDelayer(hiddenDialogOptions, actions.toggleDialogOption, null, state.dialogOptionsDelay)
                }
            }
        },
        /**
         * Archives the dialogOptions inside the messageGroup, to be activated later.
         * @param {number} messageGroupId
         * @param {string} id The id of the chosen dialog options
         */
        clearMessageGroupDialogOptions: ({ messageGroupId, id }) => state => ({
            conversation: changeMessageState({ id: messageGroupId, state }, messageGroup => {
                messageGroup.dialogOptions.options = messageGroup.dialogOptions.options.map(option => {
                    option.isSelected = option.id === id
                    option.isHidden = true

                    return option
                })
                messageGroup.dialogOptions.isActive = false
            })
        }),
        /**
         * Resets global dialogOptions container
         */
        clearDialogOptions: () => state => ({
            dialogOptions: {
                options: [],
                messageGroupId: undefined,
                path: undefined,
                isChangeable: undefined,
                isActive: undefined,
                dialogPath: undefined
            }
        }),
        /**
         * Adds dialogOptions by id to the state, can be quickreplies or dialogoptions
         * @param {number} id
         */
        setDialogOptions: id => state => {
            const { dialogOptions } = state.conversation.find(messageGroup => messageGroup.id === id)

            return ({ dialogOptions })
        },
        /**
         * Activates the dialogOptions inside the messageGroup by id
         * @param {number} id
         */
        setMessageGroupDialogOptions: id => state => ({
            conversation: changeMessageState({ id, state }, messageGroup => {
                messageGroup.dialogOptions.isActive = true
            })
        }),
        /**
         * move messages to archive
         */
        archive: () => (state, actions) => {
            const archivedMessages = state.archivedMessages || []

            archivedMessages.push(...state.conversation)

            actions.clearDialogOptions()

            return {
                archivedMessages,
                conversation: []
            }
        },
        /**
         * Fetches the faq answers
         * @param {number} id the faq id
         * @param {string} question the faq text
         * @param {boolean} isNewTopic whether a divider needs to be rendered
         * @param {boolean} setDividerBefore whether the divider needs to be rendered before the faq answer
         */
        getFaqAnswer: ({ id, question, isNewTopic, setDividerBefore }) => (state, actions) => {
            core.faqAsk({ id, question, isNewTopic, setDividerBefore })
        },
        /**
         * Toggles specific contextual faq based on id
         * @param {Object} currentFaq faq
         */
        toggleFaqs: currentFaq => state => {
            const faqs = state.faqs

            faqs.map(faq => {
                if (faq.id === currentFaq.id) {
                    faq.isHidden = false
                }

                return faq
            })

            return ({ faqs })
        },
        /**
         * Sets faqs in state
         * @param {Array} faqs
         */
        setFaqs: (faqs) => (state, actions) => {
            const mappedFaqs = faqs.map(faq => {
                const currentFaq = state.faqs.find(currentFaq => currentFaq.id === faq.id)

                if (currentFaq) {
                    return currentFaq
                }

                return { isHidden: true, ...faq }
            })

            return ({ faqs: mappedFaqs })
        },
        /**
         * Shows contextual faqs
         * @param {Object} answer
         */
        showContextualFaqs: (answer) => (state, actions) => {
            if (answer?.metadata?.relatedFaqs && answer.metadata.originalRequest?.data?.eventName !== 'show_feedback') {
                actions.setFaqs(answer.metadata.relatedFaqs)
                actions.showFaqs()
            }
        },
        /**
         * Shows all the faqs one by one
         */
        showFaqs: () => (state, actions) => {
            renderDelayer(state.faqs, actions.toggleFaqs, null, state.faqsDelay)
        },
        setElementQueries: (elementName) => () => {
            ElementQueries.init(elementName)
        },
        /**
         * Enable translation for the detected language and show the queued answer(s)
         * @returns {Object} state
         */
        enableTranslation: () => async (state, actions) => {
            try {
                const detectedLanguage = state.filteredLanguages.find(language => language.languageCode === state.detectedLanguage.languageCode)

                if (!detectedLanguage) throw new Error('detected language not found')

                await actions.setSelectedLanguage({ selectedLanguage: detectedLanguage, shouldNotify: true })
                actions.emitQueuedAnswer(state.queuedAnswer)
            } catch (error) {
                console.error(error)
            }
            actions.showModal(false)
        },
        /**
         * Disable translation and show the queued answer(s)
         * @returns {Object} state
         */
        disableTranslation: () => (state, actions) => {
            core.socket.emit('disable_translations')
            actions.emitQueuedAnswer(state.queuedAnswer?.metadata?.translation?.original)
            actions.showModal(false)
        },
        /**
         * Set language as active in list and call core to set language for translation
         * @param {Object} Object contains selectedLanguage as object or language code string and shouldNotify to trigger the core.setSelectedLanguage to show the divider and send the emit event to the server
         * @returns {Object} state
         */
        setSelectedLanguage: ({ selectedLanguage, shouldNotify, shouldResetState }) => (state, actions) => {
            if (typeof selectedLanguage === 'string') {
                // if selectedLanguage is a language code string, make it as the selectedLanguage object
                selectedLanguage = state.supportedLanguages.find(({ languageCode }) => languageCode === selectedLanguage)
            }

            const filteredLanguages = state.filteredLanguages?.map((language) => ({
                ...language,
                isActive: selectedLanguage.languageCode === language.languageCode
            }))

            if (shouldNotify) {
                const success = core.setSelectedLanguage(selectedLanguage)

                if (success) {
                    const selectedLanguageText = selectedLanguage.nativeName || selectedLanguage.name || selectedLanguage.languageCode

                    core.answer({
                        data: {
                            type: 'answer',
                            answer: [{ text: `The conversation language switched to ${selectedLanguageText}` }]
                        },
                        metadata: {
                            disableLoader: true,
                            isParagraph: true,
                            setDividerBefore: true,
                            isNewTopic: true,
                            newTopic: `${selectedLanguageText} selected`,
                            origin: 'botRouter' // We use the botrouter flow until botrouter will become default
                        },
                        type: 'ask'
                    })
                }
            }

            // Reset the selected language on a conversation reset
            // Because we have a new session, we want the default value
            if (shouldResetState) {
                selectedLanguage = undefined
            }

            return ({ filteredLanguages, selectedLanguage, selectedLanguageInput: selectedLanguage?.nativeName, detectedLanguage: undefined })
        },
        /**
         * Filters the supported languages
         * @param {String} filterValue
         * @returns {Object} state
         */
        filterSupportedLanguages: (filterValue) => (state) => ({
            filteredLanguages: state.supportedLanguages.filter(({ nativeName }) => nativeName.toLowerCase().startsWith(filterValue.toLowerCase()))
        }),
        /**
         * Show or hide the languageList
         * @param {Boolean} showLanguagesList
         * @returns {Object} state
         */
        showLanguagesList: (showLanguagesList) => () => ({ showLanguagesList }),
        /**
         * Save the current answer in the state, retrieve the detected language as object and set translation modal
         * @param {Object} answerPayload
         * @returns {Object} state
         */
        languageDetected: (answerPayload) => (state, actions) => {
            try {
                const queuedAnswer = answerPayload
                const detectedLanguage = state?.filteredLanguages?.find(filteredLanguage => filteredLanguage.languageCode === answerPayload.metadata.translation.inputLanguage)
                const modal = {
                    show: true,
                    title: `${detectedLanguage.nativeName} detected`,
                    text: `We detected a new language, do you want to automatically translate our answers to ${detectedLanguage.nativeName}?`,
                    buttons: [{ text: 'No', onClickAction: 'disableTranslation', classNames: '' }, { text: 'Yes', onClickAction: 'enableTranslation', classNames: 'cxco-c-button--outlined' }]
                }

                queuedAnswer.metadata.translation.newLanguageDetected = false // language was detected, now reset it for a clean state

                return ({ modal, detectedLanguage, queuedAnswer })
            } catch (error) {
                console.error(error)
            }
        },
        /**
         * Send answer queue and cleanup
         * @param {Object} queuedAnswer
         * @returns {Object} state
         */
        emitQueuedAnswer: (queuedAnswer) => (state, actions) => {
            core.answer(queuedAnswer)

            return ({ queuedAnswer: undefined })
        },
        /**
         * Resets the conversation
         * @param {Object} user new user from the server
         * @returns {Object} state
         */
        resetConversation: (user) => (state, actions) => {
            actions.showModal(false)
            actions.clearDialogOptions()
            actions.setFaqs([])
            actions.setSelectedLanguage({ selectedLanguage: core.config.originalLanguage, shouldNotify: false, shouldResetState: true })
            actions.setAvatarUrl('')
            actions.setAgentName('')
            // Needs to go through the state changes first before emitting to change the user (which will also change the state)
            // All the event communication from core to action causes some race condition elsewise
            core.socket.emit(SOCKET_MESSAGE_TYPES.VAL_USER, {
                ...user,
                CONFIGKEY: state.wccId,
                referer: window.location.href,
                userAgent: window.navigator.userAgent,
                resetted: true
            })
            core.socket.emit(SOCKET_MESSAGE_TYPES.LEAVE, core.storage.get('CONFIG').UID)

            return {
                inTDialog: false,
                conversation: []
            }
        },
        /**
         * Emits the reset event with the current UID to the server
         */
        sendResetEvent: () => (state, actions) => {
            core.socket.emit('reset', core.storage.get('CONFIG').UID)
        },
        /**
         * Shows the reset conversation modal
         * @returns {Object} state
         */
        showResetConversationModal: () => (state, actions) => {
            const modal = {
                show: true,
                title: state.resetModalTitle,
                text: state.resetModalText,
                buttons: [{ text: state.resetModalNoText, onClickAction: 'showModal', onClickParam: false, classNames: '' }, { text: state.resetModalYesText, onClickAction: 'sendResetEvent', classNames: 'cxco-c-button--outlined' }]
            }

            return {
                modal
            }
        },
        /**
         * Updates the show value of the modal
         * @param {Boolean} show
         * @returns {Object} state
         */
        showModal: (show) => (state) => ({
            modal: { ...state.modal, show }
        }),
        /**
         * Set avatar image url
         * @param {String} url
         * @returns {Object} state
         */
        setAvatarUrl: (url) => () => {
            return {
                avatarUrl: url
            }
        },
        /**
         * Set agent name
         * @param {String} agentName
         * @returns {Object} state
         */
        setAgentName: (agentName) => () => {
            return {
                agentName: agentName
            }
        }
    }

    return { actions, core }
}
