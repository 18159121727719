/* eslint-disable no-unused-vars */
import { h } from 'hyperapp'
import { Close } from './close'
import { Languages } from './languages'
import { Reset } from './reset'

export const Menu = () => (state, actions) => {
    return (
        <ul class="cxco-o-menu">
            <li class="cxco-o-menu__item">
                <Languages />
            </li>
            <li class="cxco-o-menu__item">
                <Reset click={actions.showResetConversationModal} />
            </li>
            <li class="cxco-o-menu__item">
                <Close click={actions.toggle} />
            </li>
        </ul >)
}
