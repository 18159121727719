/* eslint-disable no-unused-vars */
import { h } from 'hyperapp'
import { scrollElement } from '../../utils'

export const Paragraph = ({ text }) => (state, actions) => {
    if (text) {
        return (
            <article class="cxco-u-text-center" oncreate={(elm) => {
                elm.innerHTML = text
                if (elm) {
                    scrollElement(elm, 'answer')
                }
            }} />
        )
    }
}
