import { isMobile, isIOS, isSafari, isMobileOnly } from 'mobile-device-detect'
const raf = require('raf')
const window = require('global/window')
const basicLightBox = require('basiclightbox')
const debug = require('debug')('wcc:utils')
const _ = require('lodash')

const now = window.performance
    ? window.performance.now.bind(window.performance)
    : () => new Date().getTime()

/**
 * Scrolls an element into the visible part of the conversation.
 * When scrollOptions.type = 'question' the questions should always scroll but
 * shouldn't go out of the viewport in case of an answer with several bubbles.
 * When scrollOptions.type = 'answer'
 * Selected dialogs aren't questions nor top level elements (cxco-c-conversation__node) so we cannot apply a scroll to them.
 *
 * @param {Element} $el DOM element that should be scrolled
 * @param {string} type chat element type (question, answer, feedback)
 * @param {Object} scrollOptions scroll behavior, speed, etc...
 */
export function scrollElement ($el, type, scrollOptions = {}) {
    const $scrollableElement = $el.parentElement.parentElement
    const scrollToBottom = true// scrollOptions.type === 'answer'

    if (type === 'question') {
        smoothScrollIntoView($el)
    }

    if (type === 'answer' && scrollToBottom && $el?.parentElement.offsetTop > $scrollableElement.scrollTop) {
        smoothScrollIntoView($el)
    }
}

/**
 * Gets the last Question Element or Selected Dialog so it can scroll to it.
 */
// const getLastQuestionOrSelectedDialog = () => {
//     return Array.from(document.querySelectorAll('cxco-c-article')).reverse().find(element => {
//         return element.querySelectorAll('.cxco-c-bubble--inverted').length > 0 || element.querySelectorAll('.cxco-c-dialog[data-state~="selected"]').length > 0
//     })
// }

/**
 * Scrolls bubble element into visible area in the conversation.
 * @param {Element} $el
 */
const smoothScrollIntoView = ($el) => {
    try {
        var topPos = $el.parentElement.offsetTop

        // take 10 pixels from the top to leave a margin from the titlebar
        $el.parentElement.parentElement.scrollTop = topPos - 10
    } catch (error) {
        // in case browser doesnt support options and decides to throw.
        $el.scrollIntoView(true)
    }
}

/**
 * Uses raf (requestAnimationFrame) to do smoth scroll with easing
 *
 * @param {Element} $scrollable DOM element to be scrolled
 * @param {number} distance the final variation for the scrollTop position
 * @param {number} duration the animations duration
 */
export const rafSmoothScroll = ($scrollable, distance, duration = 250) => {
    const start = now()

    raf(function doScroll (timestamp) {
        const progress = (timestamp - start) / duration
        const eased = progress // easing can go here

        $scrollable.scrollTop += distance * eased

        if (eased < 1) {
            raf(doScroll)
        } else {
            // make sure we are at the bottom
            $scrollable.scrollTop += distance
        }
    })
}

/**
 * Delays the output to next() for _delay milliseconds
 *
 * @param {number} _delay seconds of delay
 */
export function delay (_delay) {
    return value => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(value)
            }, _delay)
        })
    }
}

/**
 * Adds lightbox behaviour
 * @param {Object} event Event object fired from onclick.
 */
export const addLightBoxListener = ({ target }) => {
    if (target.classList.contains('cxco-c-lightbox')) {
        try {
            basicLightBox.create(target.outerHTML).show()
        } catch (e) {
            console.error(e)
        }
    }
}

/**
 * Reads the pagePushAddition and navigates to a different location based on a relative or absolute URL string.
 * @param {string} pagePushAddition
*/
export function navigateTo (pagePushAddition) {
    const containsHttp = /^https?:\/\//i.test(pagePushAddition)
    const isRelativeLink = /^\//.test(pagePushAddition)

    if (isRelativeLink) {
        window.location.href = `https://${window.location.host.replace(/\/+$/, '')}${pagePushAddition}`
    } else if (containsHttp) {
        window.location.href = `${pagePushAddition}`
    } else {
        window.location.href = `https://${pagePushAddition}`
    }
}

/**
 * Check if a answer contains a return event and if it should either trigger or ignore it.
 * @param {Object} answer
 */
export function shouldTriggerReturnEvent ({ data, metadata }) {
    if (data.outputAdditions.triggerReturnEvent && metadata.returnEvent) {
        if (isBoolean(data.outputAdditions.triggerReturnEvent)) {
            return data.outputAdditions.triggerReturnEvent
        } else {
            return strToBool(data.outputAdditions.triggerReturnEvent)
        }
    }

    return false
}

/**
 * Returns the last message id within last messageGroup
 * We set de default return to -1 to align the template id's to the state id's
 * @param {Array} conversation
 */
export const getLastMessageGroupId = conversation => {
    if (conversation.length === 0) return -1

    const lastMessageGroup = conversation.slice(-1).pop()

    return lastMessageGroup ? lastMessageGroup.id : -1
}

/**
 * Checks if a property is type boolean
 * @param {*} prop
 */
function isBoolean (prop) {
    return typeof prop === 'boolean'
}

/**
 * convert string to boolean
 * @param {string} prop
 */
function strToBool (prop) {
    try {
        return prop.toLowerCase() === 'true'
    } catch (error) {
        debug('property was not a string', error)

        return undefined
    }
}

export const hasDialogOptions = data => data.dialogOptions && Array.isArray(data.dialogOptions) && data.dialogOptions.length > 0

export const hasQuickReplies = metadata => metadata.quickReplies && Array.isArray(metadata.quickReplies) && metadata.quickReplies.length > 0

export const getMessagesWithProperty = (conversation, property, condition = true) => {
    const arr = []

    for (let i = 0; i < conversation.length; i++) {
        for (let e = 0; e < conversation[i].messages.length; e++) {
            if (conversation[i].messages[e][property] === condition) {
                arr.push(conversation[i].messages[e])
            }
        }
    }

    return arr
}

export const scrollToOnUpdate = (scrollType, type) => (elm, oldAttrs) => {
    const grandParentElement = elm.parentElement.parentElement

    if (!grandParentElement.classList.contains('cxco-c-conversation') && elm.classList.contains('cxco-c-image')) {
        elm = elm.parentElement
    }
    if (elm.className.indexOf('cxco-u-hidden') < 0 && oldAttrs.class.indexOf('cxco-u-hidden') >= 0) {
        scrollElement(elm, type, {
            type: scrollType
        })
    }
}

/**
 * Adds the event listener for the CAIC link clicks
 * @param {HTML} elm HTML element to search for CAIC links
 * @param {Function} linkclickCallback gets called when a link is clicked
 */
export const addSendLinkClickEventListener = (elm, sendLinkClickCallback) => {
    if (!elm || !sendLinkClickCallback) return
    const CAICLinks = elm.querySelectorAll('a[data-interactionId]')

    if (!CAICLinks || !CAICLinks.length > 0) return
    CAICLinks.forEach((link) => link.addEventListener('click', sendLinkClickCallback))
}

/**
 * Detects if the device has a virtual keyboard.
 * Assumes that all mobile devices have virtual keyboard.
 * Implementation based on [MDN's Mobile device detection](https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent)
 */
export function hasVirtualKeyboard () {
    var hasTouchScreen = false

    if ('maxTouchPoints' in navigator) {
        hasTouchScreen = navigator.maxTouchPoints > 0
    } else if ('msMaxTouchPoints' in navigator) {
        hasTouchScreen = navigator.msMaxTouchPoints > 0
    } else {
        var mQ = window.matchMedia && matchMedia('(pointer:coarse)')

        if (mQ && mQ.media === '(pointer:coarse)') {
            hasTouchScreen = !!mQ.matches
        } else if ('orientation' in window) {
            hasTouchScreen = true // deprecated, but good fallback
        } else if (isMobile) {
            hasTouchScreen = true
        }
    }

    return hasTouchScreen
}

/**
 * Locks scrollable elements on iOS devices except for the chatbot scrollable area.
 * @param {boolean} isOpen
 */
export function toggleScrollLock (isOpen, element) {
    if (isIOS && isSafari && isMobileOnly && element) {
        const bodyWidth = document.body.clientWidth
        const botStyle = window.getComputedStyle(element)
        const botWidth = parseInt(botStyle.getPropertyValue('width'))
        const botMargin = parseInt(botStyle.getPropertyValue('margin-left'))
        const absoluteBotWidth = bodyWidth - (botWidth + (botMargin * 2))

        // was open and is now toggling into a closed state: remove the locks.
        if (isOpen) {
            const nonScrollableElements = document.querySelectorAll('.cxco-u-noscroll')

            nonScrollableElements.forEach((elm) => { elm.classList.remove('cxco-u-noscroll') })
        } else if (absoluteBotWidth < 10) {
            const allElementsOnPage = Array.from(document.querySelectorAll('*:not([class^="cxco-"])'))
            const scrollableElements = allElementsOnPage.filter((elm) => {
                const compStyles = window.getComputedStyle(elm)
                const val = compStyles.getPropertyValue('overflow')

                if (val !== 'hidden') {
                    return elm
                }
            })

            scrollableElements.forEach((elm) => { elm.classList.add('cxco-u-noscroll') })
        }
    }
}

/**
 * creates an unique name for CustomElementRegistry
 * @param {String} wccId
 * @returns {String} customElementName
 */
export const createCustomElementName = (wccId) => `wcc-${wccId}-${typeof window.crypto?.randomUUID === 'function' ? window.crypto?.randomUUID() : Math.random().toString(36).substring(2, 9)}`

/**
 * Splits the dialog path into an object containing the id and the nodes as an array
 * @param {String} dialogPath string that matches x:y/z/... where x is the id and everything after : are the node ids
 * @returns Object containing id and nodes
 */
export const getDialogPathData = (dialogPath) => {
    if (!dialogPath) return
    const splitDialogPath = dialogPath.split(':')

    const dialogPathData = {
        id: splitDialogPath[0],
        nodes: splitDialogPath[1].split('/')
    }

    return dialogPathData
}

/**
 * Checks whether the given dialogs (dialogPaths that has already gone through getDialogPathData) are newly triggered dialogs
 * A dialog is considered new when either the ids are completely different or when the id are the same but the second dialog is back at the first node
 * @param {Object} dialog1
 * @param {Object} dialog2
 * @returns {Boolean}
 */
export const newDialogTriggeredInDialog = (dialog1, dialog2) => {
    if (!dialog1 || !dialog2) return

    return dialog1.id !== dialog2.id || (dialog1.id === dialog2.id && dialog2.nodes.length === 1 && !_.isEqual(dialog1.nodes, dialog2.nodes))
}

/**
 * Append styletag before element or update it's text
 * @param {HTMLElement} element
 * @param {Object} stylePayload
 */
export const insertOrUpdateStyleTag = element => ({ id, cssText }) => {
    const extistingStyleTag = document.getElementById(id)
    const styleNode = extistingStyleTag || document.createElement('style')

    styleNode.textContent = cssText

    if (extistingStyleTag) return

    styleNode.id = id

    element.insertAdjacentElement('beforebegin', styleNode)
}
