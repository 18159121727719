/* eslint-disable no-unused-vars */
import { h } from 'hyperapp'
import { rafSmoothScroll, scrollElement, scrollToOnUpdate } from '../utils'
import { initSwipe } from '../plugins/swipe'
import { renderer } from '../renderer'

/**
 * Conversation component controls the conversation organizing Q&A in Bubble components.
 * @param {*} param0
 * @param {*} children - Children Composition. receives Loader componen by argument
 */
export const Conversation = (props, loader) => (state, actions) => {
    return <cxco-c-conversation class="cxco-c-conversation" role="feed" aria-busy={state.isLoading} data-layout='grouped'
        oncreate={e => {
            rafSmoothScroll(e, e.scrollHeight, 0)
        }}>
        {state.conversation.map((messageGroup) => (<Article messageGroup={messageGroup} />))}
        {loader}
    </cxco-c-conversation>
}

/**
 * Renders an article containing bubbles.
 * If the there is no text, the bubble is hidden.
 * @param {Object} param0
 */
export const Article = ({ messageGroup, isCta }) => (state, actions) => {
    const { messages, ...messageGroupProps } = messageGroup

    // override isCTAMessage, only flag it if parent element is the CTA
    messageGroupProps.isCTAMessage = isCta && messageGroupProps.isCTAMessage
    // Make subset of actions by deep copying actions used within this view only
    const msgActions = {
        toggleFeedbackMessage: () => actions.toggleFeedbackMessage(messageGroup.id),
        handleClick: actions.handleClick,
        scrollToOnUpdate: scrollToOnUpdate(state.scrollType, messageGroup.type),
        ask: actions.ask,
        sendLinkClick: actions.sendLinkClick
    }
    const rendererCallback = renderer({ ...messageGroupProps, feedbackSubmittedMessage: state.feedbackSubmittedMessage })(msgActions)

    return (<article
        class="cxco-c-article"
        id={messageGroup.id}
        oncreate={event => {
            const elements = document.querySelectorAll('.cxco-o-carrousel')

            initSwipe(elements)
            actions.onRender(event)
        }}
    >
        {
            messages.reduce(rendererCallback, [])
        }
    </article>)
}
