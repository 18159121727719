/* eslint-disable no-unused-vars */
import { h } from 'hyperapp'
import { Anchor } from './anchor'
import { addLightBoxListener } from '../../utils'
/**
 * Image component
 * @param {Object} data
 */
export const Image = ({ url, title, alignment, width, link, isHidden, isCTAMessage, type, isInline, msgActions }) => {
    const comp = (<img src={url}
        data-src={url}
        title={title}
        alt={title}
        onclick={addLightBoxListener}
        class={`cxco-c-image  ${link ? '' : 'cxco-c-lightbox'}  ${alignment} cxco-u-animation  ${type === 'question' || isCTAMessage ? 'cxco-u-animation--fade-in-right' : 'cxco-u-animation--fade-in-left'}  ${isInline || !isHidden ? '' : 'cxco-u-hidden'}`}
        style={`width: ${width}%;`}
        onupdate={msgActions.scrollToOnUpdate}
    />)

    if (link) {
        return Anchor({ ...link, text: comp, msgActions })
    } else {
        return comp
    }
}
